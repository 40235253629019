import React, { ReactNode } from 'react';

import { TActivityElementValue } from 'activities/pages/types/activity.type';
import { toActivityValueDisplay } from './ActivitiesTable.utils';
import ActivityCategoriesValue from './ActivityCategoriesValue';
import isEmpty from 'lodash/isEmpty';
import { getMultipleValuesDisplayValue, hasMultipleValues } from 'activities/utils';
import { ElementValue } from '../Drawer/ElementValue';
import { isEqual, castArray } from 'lodash';
import TruncatedText from '../BaseElements/TruncatedText/TruncatedText';

function ActivityValueCell({
  activityValue,
  extId,
  allValues,
  children,
  rows,
  flatten,
}: {
  activityValue: TActivityElementValue;
  extId?: string;
  allValues?: TActivityElementValue[];
  children?: string | number | string[] | number[];
  rows?: number;
  flatten?: boolean;
}) {
  if (hasMultipleValues(allValues, extId)) {
    const displayValue = getMultipleValuesDisplayValue(allValues, extId);
    return <ElementValue hasMultipleValues values={displayValue} />;
  }

  if (isEmpty(activityValue)) return null;

  if (activityValue.categories) {
    return <ActivityCategoriesValue flatten={flatten} activityValueCategories={activityValue.categories} />;
  }

  return (
    <TruncatedText
      rows={rows}
      text={`${toActivityValueDisplay(activityValue)}${
        children
          ? castArray(children)
              .filter((item) => typeof item !== 'object')
              .join('')
          : ''
      }`}
    />
  );
}

export default React.memo(ActivityValueCell, (prev, next) => {
  return isEqual(prev.activityValue, next.activityValue);
});
