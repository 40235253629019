import React, { useMemo } from 'react';
import { Flex, Form } from 'antd';
import { castArray } from 'lodash';
import { FormItem, FormItemController, getValueFromFormItem } from '../BaseElements/TEFormItem';
import WeekSelectorModal from '../WeekSelector/WeekSelectorModal';
import intl from 'i18n/intl';
import { TWeekSelectorBaseProps, TWeekSelectorValue } from '../WeekSelector/WeekSelector';
import { toWeeksDisplay } from 'activities/utils';

const language = intl.messages as Record<string, string>;

export default function ActivityWeeks({
  editable,
  allowMultiple,
  disabledWeeks,
  allWeeks,
  numberOfTracks,
  onSubmit,
  allowRange,
  isBatchSelection,
  required,
  name = 'weeks',
  editButtonText = language.edit,
  editWeeksLabel = language.edit_weeks,
  alwaysFulFilled,
}: TWeekSelectorBaseProps & {
  onSubmit?: (weeks: TWeekSelectorValue[], cb?: (weeks: TWeekSelectorValue[]) => void) => void | Promise<any>;
}) {
  const form = Form.useFormInstance();
  const weeks = castArray(getValueFromFormItem(Form.useWatch(name, form)) || []);
  const formattedWeeks = useMemo(() => {
    try {
      if (Array.isArray(weeks)) return weeks;
      return JSON.parse(weeks);
    } catch {
      return weeks;
    }
  }, [weeks]);

  const weeksDisplay = useMemo(() => {
    if (!formattedWeeks) return '';
    return (
      <Flex gap={6} align="center">
        {toWeeksDisplay(formattedWeeks)}
        <FormItemController name={name}>
          {({ value, onChange }) => (
            <WeekSelectorModal
              required={required}
              allowMultiple={allowMultiple}
              value={value as TWeekSelectorValue[]}
              onSubmit={(weeks) => {
                if (typeof onSubmit === 'function') return onSubmit(weeks, onChange);
                return onChange(weeks);
              }}
              editable={editable}
              disabledWeeks={disabledWeeks}
              allWeeks={allWeeks}
              numberOfTracks={numberOfTracks}
              allowRange={allowRange}
              isBatchSelection={isBatchSelection}
              editButtonText={editButtonText}
              editWeeksLabel={editWeeksLabel}
              alwaysFulFilled={alwaysFulFilled}
            />
          )}
        </FormItemController>
      </Flex>
    );
  }, [formattedWeeks, editable]);

  const weekLabel = useMemo(() => {
    if (formattedWeeks?.length === 1) return language.week;
    return language.weeks;
  }, [formattedWeeks?.length]);

  return (
    <FormItem name={editable && name} data-testid="ACTIVITY_WEEKS" label={weekLabel}>
      {weeksDisplay}
    </FormItem>
  );
}
