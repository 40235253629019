import GenerateStudentSet from '../study-combinations/pages/Views/GenerateStudentSet';
import ReviewPathwayView from '../study-combinations/pages/Views/ReviewPathwayView';
import intl from '../i18n/intl';
import { ITEToken, TNavigation } from '@timeedit/types/lib/types';
import { getVisibility } from '../utils/authentication';
import { ActivitiesOverviewPage } from '../activities/pages/ActivitiesOverviewPage';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import React from 'react';

import { trimCloudPrefixFromCustomerSignature } from '../utils/auth.helper';
import { ActivityCreateTemplateOverviewPage } from 'activities/components/Table/ActivityCreateTemplateOverviewPage';
import DataManager from 'pages/DataManager';

const language = intl.messages;

type FeatureFlagsInRouter = { dmActivityCreateTemplateManagement: boolean };

const withApplicationPathBasename =
  (customerSignature: string) =>
  (url = '') => {
    const baseName = `${process.env.REACT_APP_BRANCH_PREVIEW_BASE_NAME ? `/${process.env.REACT_APP_BRANCH_PREVIEW_BASE_NAME}` : ''}/${customerSignature}`;

    if (!window.location.pathname.startsWith(baseName)) {
      const urlRedirectToBaseName = `${window.location.origin}${baseName}${window.location.search}${window.location.hash}`;
      window.location.replace(urlRedirectToBaseName);
    }

    const baseNameAndUrl = `${baseName}/${url}`.replace('//', '/');
    return baseNameAndUrl.endsWith('/') ? baseNameAndUrl.slice(0, -1) : baseNameAndUrl;
  };

export const getDmRouter = (authUser: ITEToken) => {
  const customerSignature = trimCloudPrefixFromCustomerSignature(authUser?.customerSignature) ?? '';
  const defaultRouteStudyCombReview = `/study-combinations/review`;
  const defaultRouteActivitiesOverview = `/activities/overview`;
  const defaultRoute = getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser)
    ? defaultRouteStudyCombReview
    : defaultRouteActivitiesOverview;

  return createBrowserRouter(
    createRoutesFromElements(
      <Route path={'/'} element={<DataManager />}>
        {/* Default reroute nothing */}
        <Route index element={<Navigate to={defaultRoute} replace />} />

        {/* Default reroute only customer signature */}
        <Route index path={`/`} element={<Navigate to={defaultRoute} replace />} />

        {/* Study Combinations */}
        {getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser) && (
          <>
            <Route index path={`/study-combinations`} element={<Navigate to={defaultRouteStudyCombReview} replace />} />
            <Route path={defaultRouteStudyCombReview} element={<ReviewPathwayView />} />
            <Route path={`/study-combinations/generate`} element={<GenerateStudentSet />} />
          </>
        )}

        {/* Activities */}
        {getVisibility([], { scopes: ['TE_ACTIVITIES::user'] }, authUser) && (
          <>
            <Route index path={`/activities`} element={<Navigate to={defaultRouteActivitiesOverview} replace />} />
            <Route path={defaultRouteActivitiesOverview} element={<ActivitiesOverviewPage />} />
            <Route path={`/activities/import-template`} element={<ActivityCreateTemplateOverviewPage />} />
          </>
        )}
      </Route>,
    ),
    {
      basename: withApplicationPathBasename(customerSignature)(),
    },
  );
};

const getActivitiesNavigation = (authUser: ITEToken, { dmActivityCreateTemplateManagement }: FeatureFlagsInRouter) => {
  const showActivitesNavigation = getVisibility([], { scopes: ['TE_ACTIVITIES::user'] }, authUser);
  if (!showActivitesNavigation) {
    return [];
  }
  return [
    {
      path: `/activities`,
      label: 'Activities',
      visible: true,
      redirectTo: `/activities/overview`,
      subItems: [
        {
          label: language['activities.overview.title'] as string,
          path: `/activities/overview`,
          visible: true,
        },
        {
          label: language['activities.create_template.overview.title'] as string,
          path: `/activities/import-template`,
          visible: dmActivityCreateTemplateManagement,
        },
      ],
    },
  ];
};

const getStudyCombinationsNavigation = (authUser: ITEToken) => {
  const showStudyCombinationsNavigation = getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser);
  if (!showStudyCombinationsNavigation) {
    return [];
  }
  return [
    {
      path: `/study-combinations`,
      label: language.study_combinations as string,
      visible: true,
      redirectTo: `/study-combinations/review`,
      subItems: [
        {
          label: language['review_study_combination.title'] as string,
          path: `/study-combinations/review`,
          visible: true,
        },
        {
          label: language['generate_student_sets.title'] as string,
          path: `/study-combinations/generate`,
          visible: true,
        },
      ],
    },
  ];
};

export const getNavigation = (authUser: ITEToken, featureFlags: FeatureFlagsInRouter): TNavigation[] => {
  return [...getStudyCombinationsNavigation(authUser), ...getActivitiesNavigation(authUser, featureFlags)];
};
