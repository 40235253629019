import React, { useContext, useEffect, useMemo } from 'react';
import { EAppId, EAuth } from '@timeedit/types/lib/enums';
import './DataManager.scss';
import { useDispatch, useSelector } from 'react-redux';
import { organizationSelector } from '../slices/organization.slice';
import { StandardLayout, TopBarContent } from '@timeedit/ui-components';
import { getNavigation } from '../routes/routes';
import { EAppEnv } from '@timeedit/types/lib/enums/appEnv.enum';
import { getVisibility } from '../utils/authentication';
import { fetchMapping } from '../slices/integration.slice';
import { fetchPathwayForms } from '../study-combinations/slices/pathway.slice';
import { fetchCombinationsForms } from '../study-combinations/slices/combinationStudents.slice';
import { fetchObjectRelationships } from '../slices/objectRelationships.slice';
import { ConfigProvider, Spin, ThemeConfig } from 'antd';
import { defaultTheme } from '@timeedit/ui-components/lib/src/assets/theme/default';
import { useFetchRequiredData } from 'utils/useFetchRequiredData';
import { useAppFeatureFlags } from 'activities/hooks/useAppFeatureFlags';
import { AuthContext } from '@timeedit/ui-components/lib/src/components/AuthWrapper/AuthWrapper';
import { UserDropdown } from '@timeedit/ui-components/lib/src/components/ProductSelector/UserDropdown/UserDropdown';
import { configService } from 'services/config.service';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function DataService() {
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const authUser = useSelector((state: any) => state.auth.user);
  const authState = useContext(AuthContext)?.authState;
  const requiredDataResult = useFetchRequiredData();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const logout = useContext(AuthContext)?.logout;

  const theme = useMemo(() => {
    return {
      ...defaultTheme,
      components: {
        ...(defaultTheme.components || {}),
      },
    };
  }, [defaultTheme]);

  useEffect(() => {
    // Fetch data for study combinations,
    // if it can be disabled add a guard to check if study combinations is in permissions
    if (getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser)) {
      dispatch(fetchPathwayForms());
      dispatch(fetchCombinationsForms());
    }
    if (getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user, TE_PREFERENCES::user'] }, authUser)) {
      dispatch(fetchObjectRelationships('TE_PREFERENCES'));
    }
  }, []);

  useEffect(() => {
    if (organization?.id) {
      dispatch(fetchMapping());
    }
  }, [organization?.id]);

  const { dmActivityCreateTemplateManagement } = useAppFeatureFlags();

  if (authState === EAuth.NOT_AUTHENTICATED) {
    return <>You are not authenticated</>;
  }

  const havePermission =
    getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser) ||
    getVisibility([], { scopes: ['TE_ACTIVITIES::user'] }, authUser);

  if (!havePermission) {
    return <>You do not have permission to view this page</>;
  }

  const navigation = getNavigation(authUser, { dmActivityCreateTemplateManagement });

  if (requiredDataResult.status === 'loading') {
    return <Spin />;
  }

  const appEnv = (process.env.REACT_APP_TE_APP_ENV as EAppEnv) || EAppEnv.PRODUCTION;
  const authUrl = configService().REACT_APP_AUTH_DOMAIN;
  const customerSignature = authUser?.customerSignature;

  return (
    <div className="inner-content--wrapper">
      <div className="page page--pathway-service">
        <ConfigProvider theme={theme as unknown as ThemeConfig}>
          {/* TODO: StandardLayout does not support big content, apply overflow-y:scroll in the content ui-components */}
          {organization && authUser && (
            <StandardLayout
              navigation={navigation}
              path={pathname}
              useBuiltInRouter={false}
              topBarProps={{
                currentProduct: EAppId.TE_DATA_MANAGER,
                onClick: (path: any) => {
                  navigate(path.key);
                },
                parts: {
                  left: (
                    <>
                      <TopBarContent.ProductSelector
                        currentProduct={EAppId.TE_DATA_MANAGER}
                        user={authUser}
                        organization={organization}
                        appEnv={appEnv}
                      />
                      <TopBarContent.OrganizationName organization={organization} />
                      <TopBarContent.EnvTag appEnv={appEnv} />
                    </>
                  ),
                  right: (
                    <UserDropdown
                      user={{
                        /* TODO: firstName and lastName won't work that way. No big deal. But we should make them working eventually! */
                        firstName: authUser.firstName,
                        lastName: authUser.lastName,
                        email: authUser.email,
                        profiles: authUser.profiles,
                      }}
                      onLogout={logout}
                      customerSignature={customerSignature}
                      authUrl={authUrl}
                    />
                  ),
                },
              }}
              sideMenuProps={{
                onClick: (path) => {
                  navigate(path);
                },
              }}
            >
              <Outlet />
            </StandardLayout>
          )}
        </ConfigProvider>
      </div>
    </div>
  );
}

export default DataService;
