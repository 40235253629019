import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { Button } from '@timeedit/ui-components';
import { TTemplateSettings, useCreateNewImportTemplateModal } from './CreateNewImportTemplateModal.context';
import { TABS } from './CreateNewImportTemplateModal.constants';
import intl from 'i18n/intl';

const language = intl.messages;

export default function CreateNewImportTemplateModalFooter() {
  const { settings, tab, setTab, onClose, onNext, onPrev, onFinish, creating } = useCreateNewImportTemplateModal();

  const tabValidation = () => {
    switch (tab) {
      case TABS[0].key: {
        const fields: (keyof TTemplateSettings)[] = [
          'name',
          'activityObjectType',
          'primaryObjectType',
          'reservationMode',
        ];
        return fields.every((key) => {
          return settings[key];
        });
      }

      default:
        return true;
    }
  };

  const actionButtons = useMemo(() => {
    const isValid = tabValidation();
    switch (tab) {
      case TABS[0].key:
        return (
          <Button disabled={!isValid} type="primary" onClick={onNext}>
            {language.next as string}
          </Button>
        );
      case TABS[1].key:
        return (
          <>
            <Button onClick={onPrev}>{language.back as string}</Button>
            <Button disabled={!isValid} type="primary" onClick={onNext}>
              {language.next as string}
            </Button>
          </>
        );
      case TABS[2].key:
        return (
          <>
            <Button onClick={onPrev}>{language.back as string}</Button>
            <Button disabled={!isValid} type="primary" onClick={onNext}>
              {language.next as string}
            </Button>
          </>
        );
      case TABS[3].key:
        return (
          <>
            <Button onClick={onPrev}>{language.back as string}</Button>
            <Button type="primary" onClick={onFinish} loading={creating}>
              {language['activities.create_template.actions.download_template'] as string}
            </Button>
          </>
        );
      default:
        return null;
    }
  }, [tab, setTab, creating, settings]);
  return (
    <div className="te-flex te-pt-2" data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_FOOTER">
      <Button
        onClick={() => {
          onClose();
        }}
      >
        {language.cancel as string}
      </Button>
      <span className="te-flex-1" />
      <Flex gap="small">{actionButtons}</Flex>
    </div>
  );
}
