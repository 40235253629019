import { Alert, Button, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import WeekSelector, { TWeekSelectorBaseProps, TWeekSelectorValue } from './WeekSelector';
import { castArray, compact, sortBy } from 'lodash';
import intl from 'i18n/intl';
import { InfoCircleOutlined } from '@ant-design/icons';

const language = intl.messages as Record<string, string>;

type TWeekSelectorModalProps = TWeekSelectorBaseProps & {
  value: TWeekSelectorValue[];
  onSubmit: (value: TWeekSelectorValue[]) => void | Promise<any>;
};
export default function WeekSelectorModal(props: TWeekSelectorModalProps) {
  const {
    value,
    allowMultiple,
    onSubmit,
    editable,
    disabledWeeks,
    allWeeks,
    allowClear,
    numberOfTracks,
    allowRange,
    isBatchSelection,
    required,
    editButtonText,
    editWeeksLabel,
    alwaysFulFilled,
  } = props;

  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [localValue, setLocalValue] = useState<TWeekSelectorValue[]>(castArray(value));
  useEffect(() => {
    setLocalValue(castArray(value));
  }, [value]);

  useEffect(() => {
    if (!open) {
      setLocalValue(castArray(value));
    }
  }, [open]);

  const hasChanges = useMemo(() => {
    const toComparableWeeks = (weeks: TWeekSelectorValue[]) => {
      return sortBy(
        castArray(weeks).map((week) => {
          if (Array.isArray(week)) return week.join(',');
          return week;
        }),
      ).join(',');
    };

    if (!localValue || !value) return localValue === value;
    return toComparableWeeks(localValue) !== toComparableWeeks(value);
  }, [localValue, value]);

  const allowToSubmit = useMemo(() => {
    if (!hasChanges) return false;
    if (required) {
      return !!compact(castArray(localValue)).length;
    }
    return hasChanges;
  }, [required, hasChanges, localValue]);

  return (
    <>
      {editable && (
        <Button size="small" onClick={() => setOpen(true)}>
          {editButtonText}
        </Button>
      )}
      <Modal
        wrapClassName="weeks-selector-modal"
        open={open}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        title={editable ? editWeeksLabel : language.view_weeks}
        width={676}
        onOk={async () => {
          try {
            setSaving(true);
            await onSubmit(localValue);
            setSaving(false);
            setOpen(false);
          } catch (err) {
            // Do nothing, to prevent closing the modal if Promise is rejected
          }
        }}
        okButtonProps={{
          size: 'small',
          disabled: !allowToSubmit,
          style: {
            display: !editable ? 'none' : undefined,
          },
          loading: saving,
        }}
        cancelButtonProps={{
          size: 'small',
          type: !editable ? 'primary' : undefined,
        }}
        cancelText={language.close}
        okText={language.save}
        destroyOnClose
      >
        <WeekSelector
          value={localValue}
          onChange={setLocalValue}
          allowMultiple={allowMultiple}
          disabledWeeks={disabledWeeks}
          editable={editable}
          allWeeks={allWeeks}
          numberOfTracks={numberOfTracks}
          allowRange={allowRange}
          isBatchSelection={isBatchSelection}
          changeNote={
            hasChanges &&
            allowMultiple && (
              <Alert
                icon={<InfoCircleOutlined />}
                showIcon
                message={language['activities.warning_when_editing_weeks_of_activities_series']}
                type="info"
              />
            )
          }
          alwaysFulFilled={alwaysFulFilled}
        />
      </Modal>
    </>
  );
}
