import React, { useEffect } from 'react';
import { Form, Select, Tooltip, DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import { reservationModesSelector } from 'slices/integration.slice';
import { useExportReservationsModal } from './ExportReservationsModal.context';
import { TABS } from './ExportReservationsModal.constants';
import { QuestionCircleOutlined } from '@ant-design/icons';

import intl from 'i18n/intl';

const language = intl.messages;

export default function ExportReservationsModalMain() {
  const { file, setTab, settings, importTemplates, setSettings } = useExportReservationsModal();
  const reservationModes = useSelector(reservationModesSelector());

  useEffect(() => {
    setTab(TABS[0].key);
  }, [file]);

  const onFieldChange = (dataToUpdate: Record<string, string | boolean | Date | object | null>) => {
    setSettings({
      ...settings,
      ...dataToUpdate,
    });
  };

  return (
    <div>
      <Form layout="vertical">
        <div className="te-flex te-gap-3">
          <Form.Item
            className="te-flex-1"
            label={
              <>
                {language.reservation_mode}&nbsp;
                <Tooltip title={language.tooltip_reservation_mode as string}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <Select
              showSearch
              placeholder={language.select as string}
              data-testid="EXPORT_RESERVATIONS_SELECT_RESERVATION_MODE"
              options={reservationModes.map((mode) => ({ label: mode.name, value: mode.extId }))}
              value={settings.reservationMode?.extId}
              onChange={(type) => {
                const mode = reservationModes.find((mode) => mode.extId === type);
                onFieldChange({
                  reservationMode: { id: mode?.id, extId: type },
                });
              }}
            />
          </Form.Item>
          <Form.Item
            colon={false}
            label={
              <div>
                {language['activities.import.actions.select_template'] as string} <QuestionCircleOutlined />
              </div>
            }
          >
            <Select
              showSearch
              placeholder={language.select as string}
              filterOption={(input: string, option?: { label?: string; value?: string }) => {
                return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
              }}
              value={settings.activityCreateTemplateId}
              onChange={(importTemplateId) => setSettings({ ...settings, activityCreateTemplateId: importTemplateId })}
              style={{ width: 320 }}
              options={Object.values(importTemplates).map((importTemplate) => ({
                label: importTemplate.name,
                value: importTemplate._id,
              }))}
              data-testid="IMPORT_ACTIVITIES_MODAL_TEMPLATE_SELECTOR"
            />
          </Form.Item>
        </div>
        <div className="te-flex te-gap-3">
          <Form.Item
            className="te-flex-1"
            label={
              <>
                {language.start}&nbsp;
                <Tooltip title={language.tooltip_start_date as string}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <DatePicker
              data-testid="IMPORT_RESERVATIONS_SELECT_DATE_RANGE_START"
              value={settings.startDate}
              onChange={(date) => {
                onFieldChange({ startDate: date });
              }}
            />
          </Form.Item>
          <Form.Item
            className="te-flex-1"
            label={
              <>
                {language.end}&nbsp;
                <Tooltip title={language.tooltip_end_date as string}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <DatePicker
              data-testid="IMPORT_RESERVATIONS_SELECT_DATE_RANGE_START"
              value={settings.endDate}
              onChange={(date) => {
                onFieldChange({ endDate: date });
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
