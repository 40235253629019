import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Select, Switch, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reservationModesSelector, reservationFieldsLoadingSelector } from 'slices/integration.slice';
import { TSelectedObjectValue, useCreateNewImportTemplateModal } from './CreateNewImportTemplateModal.context';
import intl from 'i18n/intl';
import isEmpty from 'lodash/isEmpty';
import { getObjectTypeLabel } from './CreateNewImportTemplateModal.utils';

const language = intl.messages;

export default function CreateNewImportTemplateModalGeneralSettings() {
  const reservationModes = useSelector(reservationModesSelector());
  const reservationFieldsLoading = useSelector(reservationFieldsLoadingSelector);
  const { settings, setSettings, allObjectTypes, staticObjects, setStaticObjects, objects, setObjects } =
    useCreateNewImportTemplateModal();
  const objectTypesOptions = useMemo(() => {
    return Object.values(allObjectTypes).map((objectType) => {
      const label = getObjectTypeLabel(objectType, settings);
      return {
        value: objectType.extId,
        label,
        renderedLabel: objectType.name,
      };
    });
  }, [allObjectTypes, settings]);

  const onFieldChange = (dataToUpdate: Record<string, string | boolean | null>) => {
    setSettings({
      ...settings,
      ...dataToUpdate,
    });
  };

  const changePrimaryObjectType = (poType: string) => {
    // Nothing has changed
    if (poType === settings.primaryObjectType) {
      return;
    }

    const currentPrimaryObjectType = settings.primaryObjectType;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [poType]: _poType, ...remainingObjects } = objects;

    // Update dynamic object container
    if (currentPrimaryObjectType) {
      // Re-insert the primary object to the normal (dynamic) objects lookup
      setObjects({
        ...remainingObjects,
        [currentPrimaryObjectType]: {
          active: true,
          extId: currentPrimaryObjectType,
          fields: [],
          selected: true,
          label: allObjectTypes[currentPrimaryObjectType].name ?? allObjectTypes[currentPrimaryObjectType].extId,
        },
      });
    } else {
      setObjects(remainingObjects);
    }

    setSettings({ ...settings, primaryObjectType: poType });

    const primaryObjectValue: TSelectedObjectValue = {
      extId: poType,
      selected: true,
      active: true,
      fields: [],
      label: allObjectTypes[poType].name ?? allObjectTypes[poType].extId,
    };

    const modifiedStaticObjects = { ...staticObjects, primaryObject: primaryObjectValue };
    if (settings.allowAdditionalObjectsOfPrimaryObjectType) {
      modifiedStaticObjects.additionalObjectOfPrimaryObjectType = primaryObjectValue;
    }

    setStaticObjects(modifiedStaticObjects);
  };

  const changeActivityType = (atType: string) => {
    if (atType === settings.primaryObjectType) {
      return;
    }

    const currentActivityTypeType = settings.activityObjectType;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [atType]: _atType, ...remainingObjects } = objects;

    // Update dynamic object container
    if (currentActivityTypeType) {
      // Re-insert the activityType to the normal (dynamic) objects lookup
      setObjects({
        ...remainingObjects,
        [currentActivityTypeType]: {
          active: true,
          extId: currentActivityTypeType,
          fields: [],
          selected: true,
          label: allObjectTypes[currentActivityTypeType].name ?? allObjectTypes[currentActivityTypeType].extId,
        },
      });
    } else {
      setObjects(remainingObjects);
    }

    setSettings({ ...settings, activityObjectType: atType });

    setStaticObjects({
      ...staticObjects,
      activityType: {
        extId: atType,
        selected: true,
        active: true,
        fields: [],
        label: allObjectTypes[atType].name ?? allObjectTypes[atType].extId,
      },
    });
  };

  const changeAllowAdditionalObjectsOfPrimaryObjectType = (isChecked: boolean) => {
    setSettings({ ...settings, allowAdditionalObjectsOfPrimaryObjectType: isChecked });
    setStaticObjects({
      ...staticObjects,
      additionalObjectOfPrimaryObjectType:
        !isChecked || isEmpty(settings.primaryObjectType)
          ? undefined
          : {
              selected: true,
              active: true,
              fields: [],
              label:
                allObjectTypes[settings.primaryObjectType].name ?? allObjectTypes[settings.primaryObjectType].extId,
              extId: settings.primaryObjectType,
            },
    });
  };

  const changeAllowAdditionalObjectsOfActivityTypeType = (isChecked: boolean) => {
    setSettings({ ...settings, allowAdditionalObjectsOfActivityTypeType: isChecked });
    setStaticObjects({
      ...staticObjects,
      additionalObjectOfActivityTypeType:
        !isChecked || isEmpty(settings.activityObjectType)
          ? undefined
          : {
              selected: true,
              active: true,
              fields: [],
              label:
                allObjectTypes[settings.activityObjectType].name ?? allObjectTypes[settings.activityObjectType].extId,
              extId: settings.activityObjectType,
            },
    });
  };

  return (
    <div>
      <Form.Item
        label={
          <>
            {language.template_name}&nbsp;
            <Tooltip title={language.tooltip_template_name as string}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Input
          data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_INPUT_TEMPLATE_NAME"
          value={settings.name}
          onChange={(e) => setSettings({ ...settings, name: e.target.value })}
        />
      </Form.Item>
      <Form layout="vertical">
        <div className="te-flex te-gap-3">
          <Form.Item
            className="te-flex-1"
            label={
              <>
                {language.select_reservation_mode}&nbsp;
                <Tooltip title={language.tooltip_reservation_mode as string}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <Select
              showSearch
              placeholder={language.select as string}
              data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_SELECT_RESERVATION_MODE"
              options={reservationModes.map((mode) => ({ label: mode.name, value: mode.extId }))}
              value={isEmpty(settings.reservationMode) ? undefined : settings.reservationMode}
              onChange={(type) =>
                onFieldChange({ reservationMode: type, primaryObjectType: null, activityObjectType: null })
              }
            />
          </Form.Item>
          <Form.Item
            className="te-flex-1"
            label={
              <>
                {language.select_object_type_to_use_as_primary_object}&nbsp;
                <Tooltip title={language.tooltip_primary_object as string}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <Select
              loading={reservationFieldsLoading}
              data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_SELECT_PRIMARY_OBJECT"
              showSearch
              placeholder={language.select as string}
              options={objectTypesOptions.map((opt) => ({
                ...opt,
                disabled: opt.value === settings.activityObjectType,
              }))}
              value={isEmpty(settings.primaryObjectType) ? undefined : settings.primaryObjectType}
              optionLabelProp="renderedLabel"
              onChange={changePrimaryObjectType}
            />
          </Form.Item>
          <Form.Item
            className="te-flex-1"
            label={
              <>
                {language.select_object_type_to_use_as_activity_type}&nbsp;
                <Tooltip title={language.tooltip_select_object_type as string}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <Select
              loading={reservationFieldsLoading}
              data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_SELECT_ACTIVITY_TYPE"
              showSearch
              placeholder={language.select as string}
              options={objectTypesOptions.map((opt) => ({
                ...opt,
                disabled: opt.value === settings.primaryObjectType,
              }))}
              optionLabelProp="renderedLabel"
              value={isEmpty(settings.activityObjectType) ? undefined : settings.activityObjectType}
              onChange={changeActivityType}
            />
          </Form.Item>
        </div>
      </Form>
      <Form.Item
        className="te-mb-1"
        label={
          <>
            {language['activities.create_template.allow_additional_objects_of_primary_object_type']}&nbsp;
            <Tooltip title={language.tooltip_allow_additional_object_of_primary_object_type as string}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Switch
          checked={settings.allowAdditionalObjectsOfPrimaryObjectType}
          onChange={changeAllowAdditionalObjectsOfPrimaryObjectType}
          size="small"
          className="te-mr-2"
        />
      </Form.Item>
      <Form.Item
        className="te-mb-1"
        label={
          <>
            {language['activities.create_template.allow_additional_objects_of_activity_type_type']}&nbsp;
            <Tooltip title={language.tooltip_allow_additional_object_of_activity_type_type as string}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Switch
          checked={settings.allowAdditionalObjectsOfActivityTypeType}
          onChange={changeAllowAdditionalObjectsOfActivityTypeType}
          size="small"
          className="te-mr-2"
        />
      </Form.Item>
      <Form.Item
        className="te-mb-1"
        label={
          <>
            {language['activities.create_template.enable_tracks']}&nbsp;
            <Tooltip title={language.tooltip_enable_tracks as string}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Switch
          checked={settings.enableTracks}
          onChange={(checked) => onFieldChange({ enableTracks: checked })}
          size="small"
          className="te-mr-2"
        />
      </Form.Item>
    </div>
  );
}
