import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { getDmRouter } from 'routes/routes';

export function RouterWrapper() {
  const authUser = useSelector((state: any) => state.auth.user);

  const dmRouter = useMemo(() => getDmRouter(authUser), [authUser]);

  return <RouterProvider router={dmRouter} />;
}
