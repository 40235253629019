import { useFlags } from 'launchdarkly-react-client-sdk';
import { z } from 'zod';

const ZBoolDefaultFalse = z.boolean().default(false);

/** Expected feature flags with fallback values. Note: flags will automatically
 * be turned into camelCase, e.g. MY-FLAG => myFlag */
const ZAppFeatureFlags = z.object({
  exportReservationToCsv: ZBoolDefaultFalse,
  sendToReview: ZBoolDefaultFalse,
  showDmStatusFilter: ZBoolDefaultFalse,
  examFlowV3: ZBoolDefaultFalse,
  dmActivityCreateTemplateManagement: ZBoolDefaultFalse,
  bulkEditActivities: ZBoolDefaultFalse,
});

export const useAppFeatureFlags = () => {
  const ldFlags = useFlags();
  return ZAppFeatureFlags.parse(ldFlags);
};
