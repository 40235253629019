import React, { ReactNode, useState } from 'react';
import { Button, Flex, Tooltip, Typography } from 'antd';
import intl from 'i18n/intl';

const language = intl.messages as Record<string, string>;
export function ElementValue({
  hasMultipleValues,
  rows,
  children,
  editable,
  values,
}: {
  hasMultipleValues: boolean;
  rows?: number;
  children?: ReactNode;
  editable?: boolean;
  values?: any;
}) {
  const [open, setOpen] = useState(false);
  if (!hasMultipleValues) {
    return <div>{editable ? children : values}</div>;
  }

  if (!editable || !children)
    return (
      <Tooltip title={values}>
        <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: rows || 1 }}>
          {values}
        </Typography.Paragraph>
      </Tooltip>
    );
  return (
    <Tooltip title={!open && values}>
      <Flex gap="small" align="center" style={{ display: 'inline-flex' }} wrap>
        {open && children}
        {!open && (
          <>
            <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: rows || 1 }}>
              {values}
            </Typography.Paragraph>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              size="small"
            >
              {language.edit}
            </Button>
          </>
        )}
      </Flex>
    </Tooltip>
  );
}
