import React, { useEffect, useState } from 'react';
import { TActivity2Fe } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import ActivityValues from 'activities/components/Drawer/ActivityValues';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import { updateActivitiesValues } from 'activities/services/activities.service';
import { Alert, Button, ConfigProvider, Flex, Form, Modal, Space } from 'antd';
import { keyBy, pick, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import intl, { getInlineString } from 'i18n/intl';
import { activityOverviewSelector } from 'activities/pages/slices/activity.slice';
import { SeverityInfoIcon } from 'components/Icons';
import { organizationIdSelector } from 'slices/auth.slice';

const language = intl.messages as Record<string, string>;

export default function EditSelectedActivitiesModal() {
  const activityOverview = useSelector(activityOverviewSelector);
  const organizationId = useSelector(organizationIdSelector);
  const [open, setOpen] = useState(false);
  const [activities, setActivities] = useState<TActivity2Fe[]>([]);
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const [hasDisabledActivities, setHasDisabledActivities] = useState(false);
  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();

  const doGetActivities = (activityIds: string[]) => {
    const indexedActivitiesInTable = keyBy(activityOverview.activities, 'id');
    // Only get stored activities for now, can update to call api to get activities if needed
    const activities = activityIds
      .filter((id) => indexedActivitiesInTable[id])
      .map((id) => activityOverview.rowData[id] as TActivity2Fe);
    setActivities(activities);
  };
  const onOpen = (e: Event) => {
    const { detail } = e as CustomEvent<{
      activityIds: string[];
      hasDisabledActivities: boolean;
    }>;
    if (!detail) return;
    const { activityIds, hasDisabledActivities } = detail;
    setActivityIds(activityIds);
    setHasDisabledActivities(hasDisabledActivities);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setActivityIds([]);
    setHasDisabledActivities(false);
  };

  const onConfirm = async () => {
    try {
      setSaving(true);
      const values = form.getFieldsValue();
      const touchedFields = [...Object.keys(values), 'duration'].filter((field) => form.isFieldTouched(field));
      await updateActivitiesValues({
        organizationId: organizationId!,
        groupedBy: EActivityGroupings.FLAT,
        ids: activityIds,
        values: pick(values, touchedFields),
      });
      setSaving(false);
      onClose();
    } catch (err) {
      console.log('There are some invalid form values ', err);
    }
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_BULK_EDIT_ACTIVITIES, onOpen);
    return () => document.removeEventListener(ActivityEvents.OPEN_BULK_EDIT_ACTIVITIES, onOpen);
  }, []);

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  useEffect(() => {
    if (!activityIds?.length) return;
    doGetActivities(activityIds);
  }, [activityIds]);

  return (
    <Modal
      open={open}
      title={getInlineString('activities.bulk_edit_activities.title', activityIds?.length)}
      destroyOnClose
      onCancel={onClose}
      zIndex={100}
      modalRender={(modalContent) => (
        <ConfigProvider
          theme={{
            components: {
              Form: {
                itemMarginBottom: 4,
              },
            },
          }}
        >
          <Form labelCol={{ span: 8, offset: 0 }} labelAlign="left" form={form}>
            {modalContent}
          </Form>
        </ConfigProvider>
      )}
      onOk={onConfirm}
      footer={
        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
          {() => {
            const allKeys = Object.keys(form.getFieldsValue());
            const touched = form.isFieldsTouched(allKeys);
            return (
              <Space>
                <Button onClick={onClose} size="small">
                  {language.cancel}
                </Button>
                <Button disabled={!touched} onClick={onConfirm} size="small" type="primary" loading={saving}>
                  {language.confirm}
                </Button>
              </Space>
            );
          }}
        </Form.Item>
      }
    >
      <Flex vertical gap="small">
        <ActivityValues
          editable
          values={uniqBy(
            activities.flatMap((activity) => activity.values),
            'extId',
          )}
          allValues={[]}
          multipleValuesExtIds={[]}
        />
        {hasDisabledActivities && (
          <Alert
            showIcon
            type="info"
            message={language['activities.bulk_edit_activities.warning_for_in_review_activities']}
            icon={
              <span className="te-mr-2">
                <SeverityInfoIcon
                  size={14}
                  alt={language['activities.bulk_edit_activities.warning_for_in_review_activities']}
                />
              </span>
            }
          />
        )}
      </Flex>
    </Modal>
  );
}
