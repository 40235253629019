import React, { useEffect, useState } from 'react';
import { Divider, Skeleton } from 'antd';
import { TField, TTEObject } from '@timeedit/registration-shared';
import TEObjectsService from 'activities/services/TEObjects.service';
import { compact, keyBy } from 'lodash';
import DatasourceSelectorV3 from 'components/DatasourceSelectorV3';
import { FormItem, FormItemController } from '../BaseElements/TEFormItem';
import intl, { getInlineString } from 'i18n/intl';

const language = intl.messages as Record<string, string>;

export function PrimaryObjectInfo({ primaryObject }: { primaryObject?: string }) {
  const [loading, setLoading] = useState(false);
  const [objectFields, setObjectFields] = useState<Record<string, TField>>({});
  const [objectDetails, setObjectDetails] = useState<undefined | TTEObject>();
  const [objectTypeLabel, setObjectTypeLabel] = useState('');

  useEffect(() => {
    if (!primaryObject) return;
    const doGetObjectndFields = async () => {
      setLoading(true);
      const object = (await TEObjectsService.getObjects([primaryObject]))[primaryObject];
      if (!object) {
        setLoading(false);
        return;
      }
      setObjectDetails(object);
      const foundType = Object.values(TEObjectsService.objectTypes).find(({ id }) => id === object.types[0]);
      setObjectTypeLabel(TEObjectsService.getObjectTypeLabel(foundType?.extId!));
      const fields = await TEObjectsService.getFieldsById(object.fields.map(({ fieldId }) => fieldId));
      setObjectFields(keyBy(compact(Object.values(fields)), 'id'));
      setLoading(false);
    };
    doGetObjectndFields();
  }, [primaryObject]);

  return (
    <div data-testid="PRIMARY_OBJECT_INFO_IN_DRAWER">
      <Divider orientation="left">{getInlineString('object_info', objectTypeLabel)}</Divider>
      <Skeleton loading={loading}>
        {objectDetails?.fields.slice(0, 3).map((field) => (
          <FormItem
            key={field.fieldId}
            label={objectFields[field.fieldId]?.name}
            tooltip={objectFields[field.fieldId]?.description}
          >
            {field.values.join(', ')}
          </FormItem>
        ))}
      </Skeleton>
    </div>
  );
}

export function ActivityInfo({
  loading,
  editable,
  activityType,
}: {
  editable?: boolean;
  loading?: boolean;
  activityType?: string;
}) {
  const [type, setActivityType] = useState<undefined | TTEObject>();
  useEffect(() => {
    if (!activityType) return;
    const doGettingActivityType = async () => {
      const objectTypes = await TEObjectsService.getObjects([activityType]);
      setActivityType(objectTypes[activityType]);
    };
    doGettingActivityType();
  }, [!activityType]);

  return (
    <div data-testid="ACTIVITY_INFO_IN_DRAWER">
      <Divider orientation="left">{language.activity_series_info}</Divider>
      <Skeleton loading={loading}>
        <FormItem label={language.activity_type} name={editable && 'activityType'}>
          {editable ? (
            <FormItemController name="activityType">
              {({ value, onChange }) => {
                const { value: elementValue, filters } = (value as any) || {};
                return (
                  <DatasourceSelectorV3
                    allowMultiple={false}
                    value={elementValue as string[]}
                    filters={filters}
                    onChange={onChange}
                    typeId={type?.types[0]}
                    readonly
                  />
                );
              }}
            </FormItemController>
          ) : (
            <>{TEObjectsService.getObjectLabel(activityType!)}</>
          )}
        </FormItem>
      </Skeleton>
    </div>
  );
}

export function ActivityNumberOfTracks({ totalTracks }: { totalTracks?: number }) {
  return (
    <FormItem label={language.number_of_tracks} data-testid="ACTIVITY_NUMBER_OF_TRACKS_IN_DRAWER">
      {totalTracks}
    </FormItem>
  );
}
