import React, { useEffect } from 'react';
import { Button, Flex } from 'antd';
import intl from 'i18n/intl';
import { ContentPageHeader } from '@timeedit/ui-components/lib/src/components/ContentPageHeader/ContentPageHeader';
import ActivitiesTable from '../../components/Table/ActivitiesTable';
import AllActivitiesTable from '../../components/Table/AllActivitiesTable';
import ImportActivitiesModal from 'activities/components/Modal/ImportActivitiesModal';
import ExportReservationsModal from 'activities/components/Modal/ExportReservationsModal';
import CreateNewImportTemplate from 'activities/components/Modal/CreateNewImportTemplateModal';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamConfigFromApi, fetchReservationModes } from 'slices/integration.slice';
import { userSelector } from 'slices/auth.slice';
import { TEImportOutlined } from 'components/Icons';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import { useAppFeatureFlags } from 'activities/hooks/useAppFeatureFlags';
import ActivityDateRangeFilter from 'activities/components/Filters/ActivityDateRangeFilter';
import ActivityFilters from 'activities/components/Filters/ActivityFilters';
import TagsManager from 'activities/services/Tags.service';
import TEObjectManager, { useTEObjectWithLoading } from 'activities/services/TEObjects.service';
import ActivityDrawer from 'activities/components/Drawer/ActivityDrawer';
import ActivitySeriesDrawer from 'activities/components/Drawer/ActivitySeriesDrawer';
import { useActivitiesWatcher } from '../hooks/useActivitiesWatcher';
import ActivityFiltersStatusOnly from 'activities/components/Filters/ActivityFiltersStatusOnly';
import ActivityTrackDrawer from 'activities/components/Drawer/ActivityTrackDrawer';
import { AMWebsocketProvider } from 'services/am-socket.service';
import { initializeActivitiesFilter } from '../slices/activity.slice';

const language = intl.messages as Record<string, string>;

// eslint-disable-next-line react/function-component-definition
const ActivitiesOverviewPage = () => {
  useActivitiesWatcher();
  const user = useSelector(userSelector);
  const { organizationId } = user!;
  const dispatch = useDispatch();

  const onOpenImportModal = () => {
    document.dispatchEvent(new CustomEvent(ActivityEvents.OPEN_IMPORT_ACTIVITIES_MODAL));
  };

  const onOpenCreateTemplateModal = () => {
    document.dispatchEvent(new CustomEvent(ActivityEvents.OPEN_CREATE_NEW_IMPORT_TEMPLATE_MODAL));
  };

  const onOpenImportReservationsModal = async () => {
    document.dispatchEvent(new CustomEvent(ActivityEvents.OPEN_IMPORT_RESERVATIONS_MODAL));
  };

  const { exportReservationToCsv, examFlowV3, showDmStatusFilter } = useAppFeatureFlags();
  const { loading: categoriesLoading } = useTEObjectWithLoading({
    exec: () => TEObjectManager.getCategories(),
  });

  useEffect(() => {
    dispatch(initializeActivitiesFilter());
  }, []);
  useEffect(() => {
    (async () => {
      if (examFlowV3) {
        await dispatch(fetchExamConfigFromApi());
      }
      await dispatch(fetchReservationModes()); // When doing stuff with reservation modes, the exam config must be already there. Hence, we need to await here.
      await TagsManager.getAllTags();
    })();
  }, [organizationId, examFlowV3, dispatch]);

  const reservationImportButton = exportReservationToCsv ? (
    <Button className="te-mr-2" onClick={onOpenImportReservationsModal}>
      <DownloadOutlined /> {language['activities.export_reservations.title']}
    </Button>
  ) : null;

  return (
    <AMWebsocketProvider>
      <div
        className="tabpane__wrapper te-flex te-flex-col te-flex-1"
        style={{ paddingTop: 0 /* remove padding top for this page only */ }}
      >
        <ContentPageHeader
          title={language['activities.overview.title']}
          // @TODO: Temporary ignore when waiting for updates from ui-components
          // @ts-ignore
          subtitle={examFlowV3 ? <ActivityDateRangeFilter /> : language['activities.overview.description']}
          actions={
            <div className="te-flex te-items-center">
              {reservationImportButton}
              <Button className="te-mr-2" onClick={onOpenCreateTemplateModal}>
                <DownloadOutlined /> {language['activities.create_template.title']}
              </Button>
              <Button className="te-flex te-items-center" onClick={onOpenImportModal}>
                <TEImportOutlined /> {language['activities.import.title']}
              </Button>
            </div>
          }
        />
        <Flex vertical className="activity-overview-table-wrapper te-relative te-flex-1 te-min-h-0" gap="middle">
          <div />
          {/* If enable new exam flow, show all filters */}
          {examFlowV3 && categoriesLoading === false && (
            <div>
              <ActivityFilters />
            </div>
          )}
          {/* If new exam flow is not enabled, only show filters by status depended on showDmStatusFilter */}
          {!examFlowV3 && showDmStatusFilter && (
            <div>
              <ActivityFiltersStatusOnly />
            </div>
          )}
          {examFlowV3 ? <AllActivitiesTable /> : <ActivitiesTable />}
          {examFlowV3 && (
            <>
              <ActivityDrawer />
              <ActivitySeriesDrawer />
              <ActivityTrackDrawer />
            </>
          )}
          {organizationId && <ImportActivitiesModal organizationId={organizationId} />}
          {organizationId && <ExportReservationsModal organizationId={organizationId} />}
          <CreateNewImportTemplate />
        </Flex>
      </div>
    </AMWebsocketProvider>
  );
};

export { ActivitiesOverviewPage };
