/* eslint-disable camelcase */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Typography, Empty, Tag, Modal, Space } from 'antd';
import { TEBaseTable } from '@timeedit/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import StudyCombinationFooterAction from '../components/StudyCombinationFooterAction';
import { TPathway } from '../PathwayService.type';

import { MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE, ACTIVITY_DATA_ERROR } from '../PathwayServiceConstants';
import StudyCombinationFilters from '../components/StudyCombinationFilters';
import { convertToPathwayRowData, getCustomColumnLabelsBasedOnMappingAndForm } from '../PathwayService.utils';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import PathwayCoursesModal from '../components/PathwayCoursesModal';
import { useCombinationsWatchers } from '../useCombinationsWatchers';
import { StudentSetGenerationSummary } from '../components/StudentSetGenerationSummary';
import useModalConfimToAllocateStudentSets from '../components/useModalConfimToAllocateStudentSets';
import intl, { getInlineString } from '../../../i18n/intl';
import { triggerToReloadStudenSetGeneration } from '../../slices/pathway.slice';

import {
  GenerateStudentSetsSortableField,
  StudentSetGenerationStatusString,
  mongoReactSortOrderMapping,
} from '@timeedit/types/lib/constants';
import { renderNumber } from '../../utils/stringUtils';
import TEObjectLabel from '../components/TEObjectLabel';
import {
  combinationSelectedFormSelector,
  combinationSelector,
  generatingStudentCombinationSetsStatusSelector,
  combinationsCountSelector,
  generateStudentSetsCombinationsCountPerAllPagesSelector,
  generateStudentSetsChangePagination,
  updateStudentSetGenerationSorting,
  generateStudentSetsPaginationSelector,
  preparingGeneratingStudentCombinationSetsStatusSelector,
  studentSetsLoadingSelector,
  generateStudentSetsCombinationsAllIdsSelector,
  combinationFiltersSelector,
} from '../../slices/combinationStudents.slice';
import {
  TEInactiveObjectIdsSelector,
  TEObjectLabeledFieldSelector,
  TEObjectMappingSelector,
  TEObjectSelector,
} from '../../../slices/integration.slice';
import { ModalAllocationError } from '../components/ModalFixingExpectedEnrollment/ModalFixingExpectedEnrollment';
import { TestIdContext } from '../../../utils/tests/TestIdProvider';
import { useTableSelection } from 'hooks/useTableSelection';
import { paginationDefaults } from 'constants/table.constants';
import CoursesLabel from '../components/Table/CoursesLabel';

const language = intl.messages;

function GenerateStudentSet() {
  const dispatch = useDispatch();

  const { generateStudentSetsPage: currentPage } = useSelector(generateStudentSetsPaginationSelector);

  const labeledFields = useSelector(TEObjectLabeledFieldSelector());
  const mapping = useSelector(TEObjectMappingSelector());
  const form = useSelector(combinationSelectedFormSelector);
  const pathwayObjects = useSelector(TEObjectSelector(form?.pathwaysSettings.pathways.datasource));
  const courses = useSelector(TEObjectSelector(form?.pathwaysSettings.course.datasource));
  const inactiveCourses = useSelector(TEInactiveObjectIdsSelector(form?.pathwaysSettings.course.datasource));
  const filters = useSelector(combinationFiltersSelector);

  const pathwaysAreLoading = useSelector(studentSetsLoadingSelector);

  const combinations = useSelector(combinationSelector());
  const countPerAllPages = useSelector(generateStudentSetsCombinationsCountPerAllPagesSelector);
  const allIds = useSelector(generateStudentSetsCombinationsAllIdsSelector);
  const dataManagerObjectCountKeep = useSelector(combinationsCountSelector);

  const isGeneratingStudentSets = useSelector(generatingStudentCombinationSetsStatusSelector());
  const isPreparingGeneration = useSelector(preparingGeneratingStudentCombinationSetsStatusSelector());
  const [coursesModalVisible, setCoursesModalVisible] = useState<TPathway>();

  const [selectedErrorPathwayIds, setSelectedErrorPathwayIds] = useState<undefined | string[]>(undefined);
  const [generatingStudentSetsErrorMessage, setGeneratingStudentSetsErrorMessage] = useState<undefined | string>(
    undefined,
  );

  const [modal, modalContextHolder] = Modal.useModal();

  const { GenerateStudentSet: testIds } = useContext(TestIdContext);

  useCombinationsWatchers();

  const rowData = useMemo(() => {
    const latestData = combinations;
    return convertToPathwayRowData(
      latestData as TPathway[],
      { courses, pathwayObjects },
      {
        labeledCourseField: labeledFields?.[form?.pathwaysSettings?.course?.datasource || ''],
        labeledPathwayField: labeledFields?.[form?.pathwaysSettings?.pathways?.datasource || ''],
        creditField: form?.pathwaysSettings?.course?.fields?.credits,
        historicalField: form?.pathwaysSettings?.pathways.enrollment.historicalEnrollment,
        pathwayDatasource: form?.pathwaysSettings?.pathways.datasource,
        courseDatasource: form?.pathwaysSettings?.course?.datasource,
      },
    );
  }, [combinations, courses?.length, pathwayObjects?.length, labeledFields, form]);

  const customColumnLabelsBasedOnMappingAndForm = useMemo(
    () => getCustomColumnLabelsBasedOnMappingAndForm(mapping, form),
    [mapping, form],
  );

  const {
    Utils: { selectedRowKeys, onSelectedRowKeyChange, isSelectingAllInAllPage },
    Component: TableAllItemsSelection,
  } = useTableSelection({
    tableData: rowData,
    countPerAllPages,
    labels: {
      allInPageAreSelected: 'all_study_combinations_in_page_are_selected',
      selectAll: 'select_all_number_of_study_combinations',
      allInAllPageAreSelected: 'all_study_combinations_in_all_page_are_selected',
    },
    allKeys: allIds,
  });

  useModalConfimToAllocateStudentSets({
    onError: (pathwayIds, errorMessage) => {
      setSelectedErrorPathwayIds(pathwayIds);
      setGeneratingStudentSetsErrorMessage(errorMessage);
    },
    modal,
  });

  useEffect(() => {
    // Clear all selected rows when filters changed
    onSelectedRowKeyChange(undefined);
  }, [filters]);

  const onOpenCourseModal = (pathway: TPathway) => {
    setCoursesModalVisible(pathway);
  };

  const paginationEnabled = useMemo(() => {
    return !!countPerAllPages && countPerAllPages > rowData.length;
  }, [countPerAllPages, rowData.length]);

  const missingDataRowIds = useMemo(() => {
    return combinations.reduce((result: number[], d: TPathway, index: number): number[] => {
      if (
        d.studentSetGenerationStatusString?.toString() === ACTIVITY_DATA_ERROR &&
        selectedRowKeys?.includes(d._id.toString())
      ) {
        return [...result, index + 1];
      }

      return result;
    }, []);
  }, [combinations, selectedRowKeys]);

  const atLeastOneWithCertStatSel: Record<StudentSetGenerationStatusString, boolean> = useMemo(() => {
    const returnValue = {
      ACTIVITY_DATA_ERROR: false,
      COMPLETE: false,
      GENERATING: false,
      STUDENT_SETS_GENERATED: false,
      STUDENT_SETS_ALLOCATED: false,
      GENERATION_ERROR: false,
      ALLOCATION_ERROR: false,
      DEALLOCATION_ERROR: false,
    };
    for (const o of combinations as TPathway[]) {
      if (o.studentSetGenerationStatusString && selectedRowKeys?.includes(o._id)) {
        returnValue[o.studentSetGenerationStatusString] = true;
      }
    }
    return returnValue;
  }, [combinations, selectedRowKeys]);

  const columns = [
    {
      title:
        customColumnLabelsBasedOnMappingAndForm.approvedStudyCombinationsLabel ?? language.approved_study_combinations,
      key: 'id',
      width: 300,
      resizable: true,
      cellRenderer: (cell: any) => {
        return (
          <Typography.Text ellipsis>
            <span className="">
              {cell.rowData.name}
              &nbsp;&nbsp;
              <InfoCircleOutlined
                style={{ verticalAlign: 'text-bottom' }}
                onClick={() => onOpenCourseModal(cell.rowData as TPathway)}
              />
            </span>
          </Typography.Text>
        );
      },
    },
    {
      title: customColumnLabelsBasedOnMappingAndForm.courseLabel ?? language.courses,
      key: 'coursesLabel',
      width: 300,
      resizable: true,
      cellRenderer: (cell: any) => {
        return (
          <TEObjectLabel datasource={cell.rowData.fieldSettings.courseDatasource}>
            <CoursesLabel courses={cell.rowData.courses} inactiveCourses={inactiveCourses} />
          </TEObjectLabel>
        );
      },
      sortable: true,
    },
    {
      title: customColumnLabelsBasedOnMappingAndForm.historicalEnrollmentLabel ?? language.history_enrollment_shorten,
      key: 'historicalEnrollment',
      width: 150,
      cellRenderer: (cell: any) => {
        return cell.rowData.historicalEnrollment;
      },
      sortable: true,
    },
    {
      title: customColumnLabelsBasedOnMappingAndForm.expectedEnrollmentLabel ?? language.expected_enrollment_shorten,
      key: 'expectedEnrollment',
      width: 100,
      resizable: true,
      cellRenderer: (cell: any) => {
        return cell.rowData.expectedEnrollment;
      },
    },
    {
      title: 'Generated Student sets', // TODO: Translate!
      key: 'generatedStudentSets',
      width: 200,
      resizable: true,
      cellRenderer: (cell: any) => {
        return cell.rowData.generatedStudentSets;
      },
    },
    {
      title: 'Status', // TODO: Translate!
      key: 'studentSetGenerationStatusString',
      width: 230,
      cellRenderer: (cell: { rowData: TPathway }) => {
        const status = cell.rowData.studentSetGenerationStatusString as StudentSetGenerationStatusString;

        return (
          <div data-testid={testIds?.studyCombinationRowCells.generationStatus}>
            <Space>
              <Tag color={MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE[status]?.color}>
                {MAPPING_COMBINATION_STUDY_STATUS_TO_TAG_TYPE[status]?.status}
              </Tag>
              {status === 'ALLOCATION_ERROR' && cell.rowData?.allocationError?.error && (
                <InfoCircleOutlined
                  style={{ verticalAlign: 'text-bottom' }}
                  onClick={() =>
                    modal.error({
                      icon: <ExclamationCircleOutlined color="error" type="error" />,
                      title: language.student_set_allocation_failed_title as string,
                      content: (
                        <div>
                          {language.student_set_allocation_failed_description as string}
                          :
                          <br />
                          {cell.rowData?.allocationError?.message}
                        </div>
                      ),
                      onCancel: () => {},
                    })
                  }
                />
              )}
              {status === 'DEALLOCATION_ERROR' && cell.rowData?.deallocationError?.error && (
                <InfoCircleOutlined
                  style={{ verticalAlign: 'text-bottom' }}
                  onClick={() =>
                    modal.error({
                      icon: <ExclamationCircleOutlined color="error" type="error" />,
                      title: language.student_set_deallocation_failed_title as string,
                      content: (
                        <div>
                          {language.student_set_deallocation_failed_description as string}
                          :
                          <br />
                          {cell.rowData?.deallocationError?.message ??
                            (language.student_set_deallocation_failed_unknown_error as string)}
                        </div>
                      ),
                      onCancel: () => {},
                    })
                  }
                />
              )}
              {status === 'GENERATION_ERROR' && cell.rowData?.studentSetsError?.error && (
                <InfoCircleOutlined
                  style={{ verticalAlign: 'text-bottom' }}
                  onClick={() => {
                    setSelectedErrorPathwayIds([cell.rowData._id]);
                    setGeneratingStudentSetsErrorMessage(cell.rowData?.studentSetsError?.message);
                  }}
                />
              )}
              {status === 'ACTIVITY_DATA_ERROR' && (
                <InfoCircleOutlined
                  style={{ verticalAlign: 'text-bottom' }}
                  onClick={() => {
                    const renderCourseError = (courseErrorData: TPathway['mandatoryCourseError']) =>
                      courseErrorData &&
                      courseErrorData.map((c) => (
                        <>
                          <div key={c.scopedObject}>
                            <b>{c.scopedObject}</b>: <br />
                            {c.errorMessage}
                          </div>
                          <hr />
                        </>
                      ));

                    modal.error({
                      icon: <ExclamationCircleOutlined color="error" type="error" />,
                      title: language.student_set_missing_data_title as string,
                      content: (
                        <div>
                          {language.student_set_missing_data_description as string}
                          <hr />
                          <div>{renderCourseError(cell.rowData?.mandatoryCourseError)}</div>
                          <div>{renderCourseError(cell.rowData?.electiveCourseError)}</div>
                        </div>
                      ),
                      onCancel: () => {},
                    });
                  }}
                />
              )}
            </Space>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const headerSummary = useMemo(() => {
    if (!form) return [];
    return (
      <>
        {getInlineString(
          'showing_number_of_study_combinations',
          renderNumber(countPerAllPages, '-'),
          renderNumber(dataManagerObjectCountKeep, '-'),
        )}
        <StudentSetGenerationSummary selectedRowKeys={selectedRowKeys} />
      </>
    );
  }, [form, dataManagerObjectCountKeep, countPerAllPages, selectedRowKeys]);

  const onShowAlertModal = (missingDataRowIdx: number[]) => {
    modal.warning({
      title: 'Missing Activity Data',
      content: `[# ${missingDataRowIdx.join(
        ', ',
      )}] of the selected Study Combinations are missing activity data. The missing data need to be submitted in TE Preferences.`,
      onOk() {},
    });
  };

  return (
    <div className="tabpane__wrapper">
      <div className="container">
        <Typography.Title level={4}>{language['generate_student_sets.title'] as string}</Typography.Title>
        <Typography.Text>{language['generate_student_sets.subtitle'] as string}</Typography.Text>
        <StudyCombinationFilters key={form?._id} />
        <div
          className="table-wrapper te-flex te-flex-col te-flex-1"
          style={{ position: 'relative', paddingTop: 30, marginTop: 0 }}
        >
          <div style={{ position: 'absolute', top: 0 }}>{TableAllItemsSelection}</div>
          <TEBaseTable.Container>
            <TEBaseTable.Component
              tableId="TABLE_GENERATE_STUDENT_SET"
              columns={columns}
              data={rowData}
              columnsEditable
              disabled={isGeneratingStudentSets || pathwaysAreLoading}
              selectable
              rowKey="_id"
              height={rowData.length ? undefined : 250}
              draggable={false}
              loading={pathwaysAreLoading}
              footerLeftActions={
                <StudyCombinationFooterAction
                  selectedRowKeys={selectedRowKeys || []}
                  disabled={isGeneratingStudentSets || isPreparingGeneration}
                  onShowAlertModal={onShowAlertModal}
                  missingDataRowIds={missingDataRowIds}
                  atLeastOneWithCertStatSel={atLeastOneWithCertStatSel}
                  isSelectingAllInAllPage={isSelectingAllInAllPage}
                  formId={form?._id}
                  onClearSelection={() => {
                    onSelectedRowKeyChange([]);
                  }}
                />
              }
              // @ts-ignore
              emptyRenderer={
                <div className="prefs-p-4 text--disabled">
                  <Empty description="There are no study combinations" />
                </div>
              }
              selectedRowKeys={selectedRowKeys}
              onSelectedRowKeysChange={(keys) => {
                if (pathwaysAreLoading) return;
                onSelectedRowKeyChange(keys);
              }}
              headerSummary={headerSummary}
              onColumnSortChange={(order, key) => {
                const generateStudentSetsSortBy: Record<string, GenerateStudentSetsSortableField> = {
                  coursesLabel: 'metadata.courses',
                  historicalEnrollment: 'inputObject.inputContent.historicalEnrollmentCopyForSortingAndFiltering',
                  studentSetGenerationStatusString: 'studentSetGenerationStatusString',
                };
                dispatch(
                  updateStudentSetGenerationSorting({
                    generateStudentSetsSortBy: generateStudentSetsSortBy[key.toString()],
                    generateStudentSetsSortOrder: mongoReactSortOrderMapping[order],
                  }),
                );
              }}
              deactivateComponentSortingForKeys={[
                'coursesLabel',
                'historicalEnrollment',
                'studentSetGenerationStatusString',
              ]}
              enablePagination={paginationEnabled}
              onPageOrPageSizeChange={(current: number, pageSize: number) => {
                dispatch(
                  generateStudentSetsChangePagination({
                    generateStudentSetsPage: current || 1, // Sometimes values come in as "0". But we cannot have that so, pass 1 instead
                    generateStudentSetsPerPage: pageSize,
                  }),
                );
              }}
              defaultPageSize={paginationDefaults.generateStudentSetsPerPage}
              countPerAllPages={countPerAllPages}
              currentPage={currentPage}
              noOfSelectedRows={selectedRowKeys?.length}
            />
          </TEBaseTable.Container>
          <PathwayCoursesModal
            open={!!coursesModalVisible}
            onClose={() => setCoursesModalVisible(undefined)}
            pathway={coursesModalVisible}
            courses={courses}
            form={form}
          />

          <Modal
            title={language.student_set_generation_failed_description as string}
            open={!!selectedErrorPathwayIds}
            onCancel={() => setSelectedErrorPathwayIds(undefined)}
            onOk={() => setSelectedErrorPathwayIds(undefined)}
            destroyOnClose
          >
            <ModalAllocationError
              errorMessage={generatingStudentSetsErrorMessage || ''}
              pathwayIds={selectedErrorPathwayIds}
              onOk={(changed) => {
                setSelectedErrorPathwayIds(undefined);
                if (changed) {
                  dispatch(triggerToReloadStudenSetGeneration());
                }
              }}
            />
          </Modal>
        </div>
      </div>
      {modalContextHolder}
    </div>
  );
}

export default GenerateStudentSet;
