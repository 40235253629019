import React, { useEffect, useMemo, useState } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { useActivityDrawerManagement, useActivitySeriesGetter } from './ActivityDrawer.hook';
import { ActivityInfo, ActivityNumberOfTracks } from './ActivityDrawerComponents';
import ActivityValues from './ActivityValues';
import intl from 'i18n/intl';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import {
  updateActivitiesValues,
  updateActivitiesTags,
  updateActivitySeriesWeeks,
} from 'activities/services/activities.service';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import ActivityTags from './ActivityTags';
import ActivityWeeks from './ActivityWeeks';
import ActivityAddingNewTracksModal from './ActivityAddingNewTracksModal';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import TEObjectsService from 'activities/services/TEObjects.service';
import { Typography } from 'antd';
import { TWeekSelectorValue } from '../WeekSelector/WeekSelector';
import { useDispatch, useSelector } from 'react-redux';
import { organizationIdSelector } from 'slices/auth.slice';
import {
  addIdToSeriesChangedByCurrentUserSinceLastRefresh,
  ESocketUseCase,
} from 'activities/pages/slices/activity.slice';
import { FormItem } from '../BaseElements/TEFormItem';
import { uniq } from 'lodash';
import ActivityStatus from '../BaseElements/ActivityStatus/ActivityStatus';

const language = intl.messages as Record<string, string>;

function ActivitySeriesDrawer() {
  const organizationId = useSelector(organizationIdSelector);
  const [saving, setSaving] = useState(false);
  const { open, onOpen, onClose } = useActivityDrawerManagement();
  const [activitySeriesId, setActivitySeriesId] = useState<undefined | string>(undefined);
  const dispatch = useDispatch();
  const {
    activitySeries,
    loading,
    activityValues,
    allActivityValues,
    multipleValuesExtIds,
    initialValues,
    allDateRanges,
  } = useActivitySeriesGetter({
    activitySeriesId,
  });
  const editable = useMemo(() => {
    if (!activitySeries) return false;
    return activitySeries.activityStatuses.every(
      (status) => ![EActivityStatus.IN_REVIEW].includes(status as EActivityStatus),
    );
  }, [activitySeries]);

  const onSave = async (updatedValues: Record<string, string>) => {
    if (!activitySeries || !activitySeries.formId) return;
    setSaving(true);
    await updateActivitiesValues({
      organizationId: organizationId!,
      ids: [activitySeries.activitySeriesId],
      groupedBy: EActivityGroupings.ACTIVITY_SERIES,
      values: updatedValues,
    });
    if (updatedValues.tags) {
      await updateActivitiesTags({
        formId: activitySeries.formId,
        ids: [activitySeries.activitySeriesId],
        groupedBy: EActivityGroupings.ACTIVITY_SERIES,
        tags: updatedValues.tags,
      });
    }
    dispatch(addIdToSeriesChangedByCurrentUserSinceLastRefresh(activitySeries.activitySeriesId));
    setSaving(false);
    onClose();
  };

  const onOpenCallback = (e: Event) => {
    onOpen(() => {
      setActivitySeriesId((e as CustomEvent<{ activitySeriesId: string }>).detail?.activitySeriesId);
    });
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, onOpenCallback);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, onOpenCallback);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setActivitySeriesId(undefined);
      document.dispatchEvent(new CustomEvent(ActivityEvents.CLOSE_ALL_ACTIVITY_DRAWER));
    }
  }, [open]);

  const onRequestToChangeWeeks = (weeks: TWeekSelectorValue[], callback?: (_weeks: TWeekSelectorValue[]) => void) => {
    return new Promise((resolve, reject) => {
      try {
        if (organizationId && activitySeriesId) {
          updateActivitySeriesWeeks(organizationId, activitySeriesId, weeks).then(() => {
            if (typeof callback === 'function') callback(weeks);
            resolve(undefined);
          });
          dispatch(addIdToSeriesChangedByCurrentUserSinceLastRefresh(activitySeriesId));
        } else {
          resolve(undefined);
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  return (
    <ActivityDrawerWrapper
      saving={saving}
      open={open}
      onClose={onClose}
      title={`${activitySeries ? [TEObjectsService.getObjectLabel(activitySeries.primaryObject), TEObjectsService.getObjectLabel(activitySeries.activityType)].join(' - ') : 'N/A'} x${activitySeries?.numberOfTracks}`}
      subtitle={language['activites.activity_series']}
      initialValues={initialValues}
      primaryObject={activitySeries?.primaryObject}
      editable={editable}
      onSave={onSave}
      loading={loading}
      refreshCurrentViewBoxAndLogicProps={{
        useCase: { socketUseCase: ESocketUseCase.activitySeriesDrawer, activitySeriesId },
      }}
    >
      <>
        <ActivityInfo editable={false} loading={loading} activityType={activitySeries?.activityType} />
        <FormItem label={language.status}>
          {uniq(activitySeries?.activityStatuses as EActivityStatus[]).map((status) => (
            <ActivityStatus key={status} status={status} />
          ))}
        </FormItem>
        <ActivityNumberOfTracks totalTracks={activitySeries?.numberOfTracks} />
        {editable && activitySeries && (
          <ActivityAddingNewTracksModal
            activitySeries={activitySeries}
            activityValues={activityValues}
            initialValues={initialValues}
            onClose={onClose}
          />
        )}
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activityValues}
          allValues={allActivityValues}
          title={language.track_info}
          multipleValuesExtIds={multipleValuesExtIds}
          durations={activitySeries?.durations}
        >
          <div className="te-mb-2">
            <p className="te-mt-2">
              <Typography.Text>{language['activities.warning_when_editing_activities_in_series']}</Typography.Text>
            </p>
          </div>
          <ActivityWeeks
            allowMultiple
            editable={editable}
            allWeeks={allDateRanges}
            numberOfTracks={activitySeries?.numberOfTracks}
            disabledWeeks={[]}
            onSubmit={onRequestToChangeWeeks}
            isBatchSelection
            required
          />
        </ActivityValues>
        <ActivityTags formId={activitySeries?.formId?.toString()} editable={editable} />
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivitySeriesDrawer;
