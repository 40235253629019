import React, { ReactNode, useEffect, useRef } from 'react';
import {
  Button,
  Drawer,
  Space,
  ConfigProvider,
  DrawerProps,
  Form,
  FormInstance,
  Modal,
  Typography,
  Alert,
  Flex,
} from 'antd';
import './ActivityDrawerWrapper.scss';
import intl from 'i18n/intl';
import { PrimaryObjectInfo } from './ActivityDrawerComponents';
import { ActivityEvents, TActivityRawValue } from 'activities/pages/types/activity.type';
import { pick } from 'lodash';
import RefreshCurrentViewBoxAndLogic, {
  TRefreshCurrentViewBoxAndLogicProps,
} from '../Socket/RefreshCurrentViewBoxAndLogic';
import { setCurrentPageContainsDataToBeRefreshed } from 'activities/pages/slices/activity.slice';
import { useDispatch } from 'react-redux';
import { SeverityAlertIcon, SeverityInfoIcon, SeverityWarningIcon } from 'components/Icons';

const language = intl.messages as Record<string, string>;

type TDrawerOptions = DrawerProps & {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  initialValues?: Record<string, TActivityRawValue>;
  primaryObject?: string;
  editable?: boolean;
  loading?: boolean;
  saving?: boolean;
  onSave?: (values: Record<string, string>) => void;
  onClose?: () => void;
  afterOpenChange?: () => void;
  refreshCurrentViewBoxAndLogicProps: TRefreshCurrentViewBoxAndLogicProps;
};

function DrawerTitle({ title, subtitle }: { title: string; subtitle?: string }) {
  return (
    <div className="te-flex te-flex-col">
      <Typography.Title level={4}>{title}</Typography.Title>
      {subtitle && <Typography.Text style={{ fontWeight: 'normal' }}>{subtitle}</Typography.Text>}
    </div>
  );
}

type TDrawerActions = {
  onDiscard?: () => void;
  onSave?: () => void;
  form: FormInstance;
  saving?: boolean;
};
function DrawerActions({ onDiscard, onSave, saving, form }: TDrawerActions) {
  return (
    <Form form={form}>
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => {
          const allKeys = Object.keys(form.getFieldsValue());
          const touched = form.isFieldsTouched(allKeys.filter((key) => key !== 'weeks'));
          return (
            <Space>
              <Button disabled={!touched} onClick={onDiscard} size="small">
                {language.discard_changes}
              </Button>
              <Button disabled={!touched} onClick={onSave} size="small" type="primary" loading={saving}>
                {language.save}
              </Button>
            </Space>
          );
        }}
      </Form.Item>
    </Form>
  );
}

function ActivityDrawerWrapper(props: TDrawerOptions) {
  const {
    children,
    subtitle,
    title,
    onClose,
    open,
    initialValues,
    primaryObject,
    editable,
    loading,
    saving,
    onSave,
    afterOpenChange,
    refreshCurrentViewBoxAndLogicProps,
  } = props;
  const [form] = Form.useForm();
  const [modal, modalContextHolder] = Modal.useModal();
  const dispatch = useDispatch();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    form.setFieldsValue(initialValues);
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  const onSaveValues = async () => {
    if (typeof onSave !== 'function') return;
    try {
      const values = await form.validateFields();
      const fields = Object.keys(values);
      const touchedFields = fields.filter((field) => form.isFieldTouched(field));
      if (touchedFields.length) {
        onSave(pick(values, touchedFields));
      }
    } catch (err) {
      console.log('Error when saving activity', err);
    }
  };

  const checkAndClose = () => {
    const allKeys = Object.keys(form.getFieldsValue());
    const touched = form.isFieldsTouched(allKeys.filter((key) => key !== 'weeks'));
    if (touched) {
      modal.confirm({
        title: language.ask_to_save_all_changes,
        okButtonProps: { color: 'primary' },
        okText: language.save,
        cancelText: language.discard_changes,
        onOk: onSaveValues,
        onCancel: onCloseDrawer,
        icon: <SeverityWarningIcon alt={language.ask_to_save_all_changes} />,
      });
      return;
    }
    if (typeof onClose === 'function') {
      dispatch(
        setCurrentPageContainsDataToBeRefreshed({
          socketUseCase: refreshCurrentViewBoxAndLogicProps.useCase.socketUseCase,
          newValue: false,
        }),
      );
    }
    onCloseDrawer();
  };

  const onCloseDrawer = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const closeDrawer = (e: MouseEvent) => {
    if (!open) return;
    const target = e.target as HTMLElement;
    if (
      drawerRef.current?.contains(target) ||
      target.closest('.ant-modal-confirm') ||
      target.closest('.ant-drawer-content') ||
      target.closest('.ant-table-cell') ||
      target.tagName === 'a' ||
      target.tagName === 'INPUT' ||
      target.tagName === 'SPAN' ||
      target.classList.contains('ant-select-selection-overflow') ||
      target.classList.contains('ant-modal-wrap') ||
      target.classList.contains('ant-modal-content') ||
      target.closest('.ant-modal-content')
    ) {
      return;
    }
    checkAndClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeDrawer);

    return () => {
      document.removeEventListener('mousedown', closeDrawer);
    };
  }, [onSaveValues, open]);

  useEffect(() => {
    document.addEventListener(ActivityEvents.CLOSE_ACTIVITY_OVERVIEW_DRAWER, onCloseDrawer);
    return () => {
      document.removeEventListener(ActivityEvents.CLOSE_ACTIVITY_OVERVIEW_DRAWER, onCloseDrawer);
    };
  }, []);

  return (
    <Drawer
      data-testid="ACTIVITY_DRAWER_WRAPPER"
      className="activity-details"
      open={open}
      onClose={checkAndClose}
      title={<DrawerTitle title={title} subtitle={subtitle} />}
      extra={
        editable && (
          <DrawerActions form={form} onDiscard={() => form.resetFields()} onSave={onSaveValues} saving={saving} />
        )
      }
      width={480}
      maskClosable={false}
      getContainer={document.querySelector('.activity-overview-table-wrapper') || document.body}
      rootStyle={{
        position: 'absolute',
      }}
      afterOpenChange={afterOpenChange}
      destroyOnClose
      mask={false}
    >
      <div ref={drawerRef}>
        <ConfigProvider
          theme={{
            components: {
              Form: {
                itemMarginBottom: 4,
              },
            },
          }}
        >
          <Flex vertical gap="middle">
            {/* I disagree with this rule. Prop spreading is super usefulll in this case because this component should not care about the props at all and just pass them through */
            /* eslint-disable-next-line react/jsx-props-no-spreading */}
            <RefreshCurrentViewBoxAndLogic {...refreshCurrentViewBoxAndLogicProps} />
            {!editable && (
              <Alert
                type="info"
                showIcon
                icon={
                  <span className="te-mr-2">
                    <SeverityInfoIcon size={14} />
                  </span>
                }
                message={language['activities.overview.warning_not_editable_for_in_review_activities']}
              />
            )}
          </Flex>
          <Form
            layout="horizontal"
            labelCol={{ span: 8, offset: 0 }}
            labelAlign="left"
            labelWrap
            form={form}
            initialValues={initialValues}
          >
            {children}
          </Form>
        </ConfigProvider>
        {modalContextHolder}
      </div>
    </Drawer>
  );
}

export default ActivityDrawerWrapper;
