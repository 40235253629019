import React, { ReactNode, useCallback } from 'react';
import {
  TActivity2Be,
  TActivity2Fe,
} from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { Divider, Input, Skeleton, Tag } from 'antd';
import { FormItem, FormItemController } from '../BaseElements/TEFormItem';
import Duration from '../BaseElements/Duration';
import TEObjectsService from 'activities/services/TEObjects.service';
import DatasourceSelectorV3 from 'components/DatasourceSelectorV3';
import ActivityValueCell from '../Table/ActivityValueCell';
import intl from 'i18n/intl';
import { ElementValue } from './ElementValue';
import { getMultipleValuesDisplayValue, toDurationDisplay } from 'activities/utils';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';

const language = intl.messages as Record<string, string>;

type TActivityValuesProps = {
  track?: number;
  values?: TActivity2Be['values'];
  allValues?: TActivity2Be['values'];
  loading?: boolean;
  title?: string;
  editable?: boolean;
  multipleValuesExtIds?: string[];
  children?: ReactNode;
  formId?: null | string;
  timingDisabled?: boolean;
  durations?: number[];
};

function ActivityFieldValue({
  extId,
  hasMultipleValues,
  allValues,
}: TActivity2Be['metadata']['field'][number] & { hasMultipleValues: boolean; allValues: TActivity2Fe['values'] }) {
  return (
    <FormItem label={TEObjectsService.getFieldLabel(extId)}>
      <ElementValue
        hasMultipleValues={hasMultipleValues}
        values={getMultipleValuesDisplayValue(allValues, extId)}
        editable
        rows={2}
      >
        <FormItem name={extId}>
          <Input size="small" />
        </FormItem>
      </ElementValue>
    </FormItem>
  );
}

function ActivityObjectValue({
  extId,
  submissionType,
  hasMultipleValues,
  multipleValuesRenderer,
}: TActivity2Be['metadata']['object'][number] & { hasMultipleValues: boolean }) {
  return (
    <FormItem name={extId} label={TEObjectsService.getObjectTypeLabel(extId)}>
      <FormItemController name={extId}>
        {({ value, onChange, touched }) => {
          const { value: elementValue, filters, valueType } = (value as any) || {};

          if (elementValue || filters) {
            return (
              <DatasourceSelectorV3
                allowMultiple
                value={elementValue as string[]}
                filters={filters}
                onChange={({ value, filters }) => onChange({ value, filters, valueType })}
                typeExtId={extId}
                type={submissionType}
                hasMultipleValues={!touched && hasMultipleValues}
                multipleValuesRenderer={!touched && multipleValuesRenderer}
              />
            );
          }
          return (
            <DatasourceSelectorV3
              allowMultiple
              value={value as string[]}
              typeExtId={extId}
              onChange={onChange}
              hasMultipleValues={!touched && hasMultipleValues}
              multipleValuesRenderer={!touched && multipleValuesRenderer}
            />
          );
        }}
      </FormItemController>
    </FormItem>
  );
}

export function ActivityTracks({ track }: { track?: number }) {
  if (track === undefined) return null;
  return <FormItem label={language.track}>#{track}</FormItem>;
}

function ActivityValues(props: TActivityValuesProps) {
  const { loading, title, values, allValues, editable, children, multipleValuesExtIds, timingDisabled, durations } =
    props;
  const multipleValuesRenderer = useCallback(
    (extId: string) => {
      return <ElementValue rows={2} hasMultipleValues values={getMultipleValuesDisplayValue(allValues, extId)} />;
    },
    [allValues],
  );

  return (
    <div data-testid="ACTIVITY_VALUES_IN_DRAWER">
      {title && <Divider orientation="left">{title}</Divider>}

      <Skeleton loading={loading}>
        {children}
        <FormItem label={language.duration}>
          <ElementValue
            values={durations?.map(toDurationDisplay).join('; ')}
            editable={editable}
            hasMultipleValues={!!durations && durations.length > 1}
          >
            <FormItemController
              name="duration"
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error())),
                },
              ]}
              type="number"
            >
              {({ value, onChange }) => (
                <Duration value={value as number} onChange={onChange} disabled={!editable || timingDisabled} />
              )}
            </FormItemController>
          </ElementValue>
        </FormItem>
        {values?.map((val) => {
          const hasMultipleValues = multipleValuesExtIds?.includes(val.extId);
          if (!editable) {
            return (
              <FormItem
                label={TEObjectsService.getObjectTypeLabel(val.extId, TEObjectsService.getFieldLabel(val.extId))}
                key={val.extId}
              >
                <ActivityValueCell flatten activityValue={val.value} extId={val.extId} allValues={allValues} rows={2} />
              </FormItem>
            );
          }

          switch (val.type) {
            case 'object': {
              return (
                <ActivityObjectValue
                  key={val.extId}
                  editable
                  extId={val.extId}
                  submissionType={val.submissionValueType}
                  hasMultipleValues={hasMultipleValues}
                  multipleValuesRenderer={multipleValuesRenderer}
                />
              );
            }
            case 'field':
              return (
                <ActivityFieldValue
                  key={val.extId}
                  extId={val.extId}
                  hasMultipleValues={hasMultipleValues}
                  allValues={allValues}
                />
              );
            default:
              return (
                <ActivityFieldValue
                  key={val.extId}
                  extId={val.extId}
                  hasMultipleValues={hasMultipleValues}
                  allValues={allValues}
                />
              );
          }
        })}
      </Skeleton>
    </div>
  );
}

export default ActivityValues;
