/* eslint-disable no-param-reassign */
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Subject, groupBy, mergeMap, toArray, timer, map } from 'rxjs';

import * as activityService from 'activities/services/activities.service';
import { IAuthState, IDefaultReducerState } from 'types/state.interface';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import {
  ActivityEvents,
  TActivityResultsInResponse,
  TActivityResultsInResponseGroupByActivitySeries,
  TActivityResultsInResponseGroupByFlat,
  TActivityResultsInResponseGroupByTrack,
  TActivityRowData,
  TActivityRowRecord,
} from '../types/activity.type';
import { ApplicationState } from 'slices';
import { TTemplateReservationField } from '../types/importTemplate.type';
import { TField } from '@timeedit/types/lib/types';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import { Dayjs } from 'dayjs';
import TEObjectsService from 'activities/services/TEObjects.service';
import { castArray, pick, omit, uniq, compact } from 'lodash';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { TObjectFilterValues } from '@timeedit/ui-components/lib/src/components/ObjectFilter/ObjectFilter.type';
import { TActivity2Fe } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { TActivityValue } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityValue.type';
import localStorageHelper from 'utils/localStorage.helper';
import {
  TActivitySeriesUpdatePayload,
  TActivityUpdatePayload,
  TSocketEventAndPayload,
} from '@timeedit/activity-manager-shared-lib/lib/internal/types/WebSocket.type';
import { trackDivider } from 'activities/services/activities.service';

type TBasicFilterItem = undefined | string | string[] | Dayjs[];
type TCategoriedFilterItem = Record<string, string | string[]>;

type TPagination = {
  page: number;
  perPage: number;
  totalActivities: number;
  allActivitiesCount: number;
};

type TActivitiesFetchResult = {
  results: TActivityResultsInResponse[];
  totalResults: number;
  totalItems: number;
  allKeys: string[];
};

export enum ESocketUseCase {
  overviewTable = 'overviewTable',
  singleActivityDrawer = 'singleActivityDrawer',
  activitySeriesDrawer = 'activitySeriesDrawer',
  activityTrackDrawer = 'activityTrackDrawer',
}
export type TSocketPageChangedState = 'updated' | 'drawerItemDeleted' | false;

export interface ActivityState {
  activities: TActivityResultsInResponse[];
  allActivitySeriesIds: string[];
  selectedIds?: string[];
  trigger: number;
  pagination: Record<EActivityGroupings.FLAT | EActivityGroupings.ACTIVITY_SERIES, TPagination>;
  filterPeriod?: string[] | Dayjs[];
  filter: {
    status?: EActivityStatus[];
    [key: string]: TBasicFilterItem | TCategoriedFilterItem;
  };
  filterInitialized: boolean;
  finalFilterValues?: activityService.TActivityFilterNestedObject;
  expandedRows: string[];
  categoryFilters: Record<string, TObjectFilterValues>;
  changedCategories: string[];
  loadings: {
    fetch: IDefaultReducerState;
    delete: IDefaultReducerState;
    send: IDefaultReducerState;
  };
  groupBy: TActivityGroupBy;
  selectedActivityId?: string;
  activitySeries?: string[];
  activityIds?: string[];
  overview: {
    series?: TActivityRowRecord[]; // keep basic information of activity series: { id: string, children: any }
    activities?: TActivityRowRecord[]; // keep basic information of activities: { id: string }
    rowData: Record<string, Omit<TActivityRowData, 'activityReviewStatus'>>; // all series, tracks and activities details
    allOtherValues?: string[];
    allObjects: string[];
    allFields: string[];
    triggers: {
      ids: string[];
      tracker: number;
    };
  };
  currentPageContainsDataToBeRefreshed: { [key in ESocketUseCase]: TSocketPageChangedState };
  refreshedActivitiesOfCurrentPage?: TActivitiesFetchResult;
  seriesChangedByCurrentUserSinceLastRefresh: string[];
}

export const initialState: ActivityState = {
  activities: [],
  allActivitySeriesIds: [],
  selectedIds: [],
  pagination: {
    FLAT: {
      page: 1,
      perPage: 100,
      totalActivities: 0,
      allActivitiesCount: 0,
    },
    ACTIVITY_SERIES: {
      page: 1,
      perPage: 100,
      totalActivities: 0,
      allActivitiesCount: 0,
    },
  },
  filterPeriod: localStorageHelper.filters.period,
  filter: {
    status: undefined,
  },
  filterInitialized: false,
  expandedRows: [],
  categoryFilters: {},
  changedCategories: [],
  trigger: 0,
  loadings: {
    fetch: {
      loading: false,
      hasErrors: false,
    },
    delete: {
      loading: false,
      hasErrors: false,
    },
    send: {
      loading: false,
      hasErrors: false,
    },
  },
  groupBy: EActivityGroupings.ACTIVITY_SERIES,
  overview: {
    rowData: {},
    allOtherValues: undefined,
    allObjects: [],
    allFields: [],
    triggers: {
      ids: [],
      tracker: 0,
    },
  },
  currentPageContainsDataToBeRefreshed: {
    overviewTable: false,
    singleActivityDrawer: false,
    activitySeriesDrawer: false,
    activityTrackDrawer: false,
  },
  seriesChangedByCurrentUserSinceLastRefresh: [],
};

const allocationSlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    fetchActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.fetch);
    },
    fetchActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.fetch);
    },
    fetchActivitiesSuccess: (
      state: ActivityState,
      {
        payload,
      }: {
        payload: {
          results: TActivityResultsInResponse[];
          totalItems: number;
          totalResults: number;
          allKeys: string[];
        };
      },
    ) => {
      const { results, allKeys, totalItems, totalResults } = payload;
      finishedLoadingSuccess(state.loadings.fetch);
      state.activities = results;
      state.allActivitySeriesIds = allKeys;
      state.pagination[state.groupBy].totalActivities = totalResults;
      state.pagination[state.groupBy].allActivitiesCount = totalItems;

      state.changedCategories = [];
      state.overview.rowData = state.overview.rowData || {};
      if (state.groupBy === 'ACTIVITY_SERIES') {
        state.overview.allOtherValues = uniq(
          (results as TActivityResultsInResponseGroupByActivitySeries[]).flatMap((item) =>
            item.allValues.flatMap((sub) => sub.activityValue.extId),
          ),
        );
        state.overview.series = (results as TActivityResultsInResponseGroupByActivitySeries[]).map((item) => {
          state.overview.rowData[item.activitySeriesId] = activityService.convertToActivityOverview(
            item,
            state.overview.allOtherValues,
          );
          return {
            id: item.activitySeriesId,
            children: new Array(item.numberOfTracks).fill(undefined).map((_item, itemIndex) => ({
              id: activityService.convertToTrackId(item.activitySeriesId, 1 + itemIndex),
            })),
            level: 'series',
          };
        });
        state.seriesChangedByCurrentUserSinceLastRefresh = [];
      } else {
        state.overview.activities = (results as TActivityResultsInResponseGroupByFlat[]).map((activity) => {
          state.overview.rowData[activity._id] = activityService.convertToActivityOverview(
            activity,
            state.overview.allOtherValues,
          );
          return {
            id: activity._id,
            level: 'activity',
          };
        });
      }

      // Clear old data in overview
      const allDataKeys = [
        ...(state.overview.series?.map(({ id }) => id) || []),
        ...(state.overview.activities?.map(({ id }) => id) || []),
        ...(state.selectedIds || []),
      ];
      Object.keys(state.overview.rowData).forEach((key) => {
        if (!allDataKeys.includes(key)) {
          delete state.overview.rowData[key];
        }
      });
    },
    finishLoadingActivities: (state: ActivityState) => {
      finishedLoadingSuccess(state.loadings.fetch);
    },

    fetchActivitiesIntoTempState: (
      state: ActivityState,
      { payload }: { payload: TActivitiesFetchResult & { addedActivityIds?: string[] } },
    ) => {
      if (
        payload.results.some(
          (item) =>
            ('_id' in item && payload.addedActivityIds?.includes(item._id)) || // Case: FLAT
            ('activityIds' in item && item.activityIds.some((id) => payload.addedActivityIds?.includes(id))), // Case: ACTIVITY_SERIES
        )
      ) {
        delete payload.addedActivityIds;
        state.refreshedActivitiesOfCurrentPage = payload;
        state.currentPageContainsDataToBeRefreshed.overviewTable = 'updated';
      }
    },

    removeRefreshedActivitiesOfCurrentPage: (state: ActivityState) => {
      state.refreshedActivitiesOfCurrentPage = undefined;
    },

    setCurrentPageContainsDataToBeRefreshed: (
      state: ActivityState,
      { payload }: { payload: { socketUseCase: ESocketUseCase; newValue: TSocketPageChangedState } },
    ) => {
      state.currentPageContainsDataToBeRefreshed[payload.socketUseCase] = payload.newValue;
    },

    fetchActivitiesChildrenRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.fetch);
    },
    fetchActivitiesChildrenFailure: (state: ActivityState) => {
      isLoadingRequest(state.loadings.fetch);
    },
    fetchActivitiesChildrenSuccess: (state: ActivityState, { payload }) => {
      const { results, activitySeriesId } = payload;
      state.activities = state.activities.map((activity) => {
        if ((activity as TActivityResultsInResponseGroupByActivitySeries).activitySeriesId === activitySeriesId) {
          (activity as TActivityResultsInResponseGroupByActivitySeries).children = results;
        }
        return activity;
      });
    },

    fetchTracksInSeriesSuccess: (
      state: ActivityState,
      { payload: { tracks } }: { payload: { tracks: TActivityResultsInResponseGroupByTrack[] } },
    ) => {
      const activitySeriesId = tracks?.[0].activitySeriesId;
      if (!activitySeriesId || !state.overview.series) return;
      const foundSeriesIdx = state.overview.series?.findIndex(({ id }) => id === activitySeriesId);
      if (foundSeriesIdx > -1 && state.overview.series) {
        state.overview.series[foundSeriesIdx].children = tracks.map((track) => {
          return {
            id: activityService.convertToTrackId(track.activitySeriesId, track.trackId),
            children: track.activityIds.map((id: string) => ({ id })),
            activitySeriesId,
            trackId: track.trackId,
            level: 'track',
          };
        });
        tracks.forEach((track) => {
          const trackId = activityService.convertToTrackId(track.activitySeriesId, track.trackId);
          state.overview.rowData[trackId] = activityService.convertToActivityOverview(
            {
              ...track,
              allValues: track.allValues.map((item) => ({
                activityValue: item,
              })),
            },
            state.overview.allOtherValues,
          );
        });
      }
    },
    fetchActivitiesInTrackSuccess: (state: ActivityState, { payload: { activities } }) => {
      if (!activities.length) return;
      activities.forEach((activity: TActivity2Fe) => {
        state.overview.rowData[activity._id.toString()] = activityService.convertToActivityOverview(
          activity,
          state.overview.allOtherValues,
        );
      });
      finishedLoadingSuccess(state.loadings.fetch);
    },
    refetchActivitySeriesByIdSuccess: (
      state: ActivityState,
      { payload }: { payload: { results: TActivityResultsInResponseGroupByActivitySeries[] } },
    ) => {
      payload.results.forEach((updatedSeries) => {
        state.overview.rowData[updatedSeries.activitySeriesId] = activityService.convertToActivityOverview(
          updatedSeries,
          state.overview.allOtherValues,
        );
      });
      state.overview.triggers.tracker += 1;
      state.overview.triggers.ids = payload.results.map((series) => series.activitySeriesId);
      state.seriesChangedByCurrentUserSinceLastRefresh = [];
      finishedLoadingSuccess(state.loadings.fetch);
    },
    refetchActivityByIdSuccess: (state: ActivityState, { payload }: { payload: { results: TActivity2Fe[] } }) => {
      payload.results.forEach((activity) => {
        state.overview.rowData[activity._id] = activityService.convertToActivityOverview(
          activity,
          state.overview.allOtherValues,
        );
      });
      state.overview.triggers.tracker += 1;
      state.overview.triggers.ids = payload.results.map(({ _id }) => _id);
      finishedLoadingSuccess(state.loadings.fetch);
    },

    publishActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.send);
    },
    publishActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.send);
    },
    publishActivitiesSuccess: (
      state: ActivityState,
      { payload }: { payload: { response: unknown; activitySeriesIds: string[] } },
    ) => {
      state.selectedIds = [];
      state.seriesChangedByCurrentUserSinceLastRefresh = [
        ...state.seriesChangedByCurrentUserSinceLastRefresh,
        ...payload.activitySeriesIds,
      ];
      finishedLoadingSuccess(state.loadings.send);
    },

    deleteActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.delete);
    },
    deleteActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.delete);
    },
    deleteActivitiesSuccess: (
      state: ActivityState,
      { payload }: { payload: { response: unknown; activitySeriesIds: string[] } },
    ) => {
      state.selectedIds = [];
      state.seriesChangedByCurrentUserSinceLastRefresh = [
        ...state.seriesChangedByCurrentUserSinceLastRefresh,
        ...payload.activitySeriesIds,
      ];
      finishedLoadingSuccess(state.loadings.delete);
    },

    changeTablePagination: (
      state: ActivityState,
      { payload: { page, perPage } }: { payload: { page?: number; perPage?: number } },
    ) => {
      if (page !== undefined) {
        state.pagination[state.groupBy].page = page;
      }
      if (perPage !== undefined) {
        state.pagination[state.groupBy].perPage = perPage;
      }
      state.currentPageContainsDataToBeRefreshed.overviewTable = false;
      state.refreshedActivitiesOfCurrentPage = undefined;
    },

    initializeActivitiesFilter: (state: ActivityState) => {
      state.filter = localStorageHelper.filters.values as ActivityState['filter'];
      state.filterPeriod = localStorageHelper.filters.period;
      state.filterInitialized = true;
    },
    changeFilterValue: (state: ActivityState, { payload }) => {
      const deletedKeys = Object.keys(state.filter).filter((item) => !payload[item]);
      const updatedFilter = {
        ...omit(
          {
            ...state.filter,
            ...payload,
          },
          deletedKeys,
        ),
      };
      // Set to first page
      state.pagination[state.groupBy].page = 1;
      if (JSON.stringify({ ...state.filter }) !== JSON.stringify(updatedFilter)) {
        state.filter = updatedFilter;
      }
    },

    changeFilterPeriodValue: (state: ActivityState, { payload }) => {
      state.filterPeriod = payload;
    },

    setFinalFilters: (state: ActivityState, { payload }: { payload: activityService.TActivityFilterNestedObject }) => {
      state.finalFilterValues = payload;
    },

    changeExpandedRows: (state: ActivityState, { payload }: { payload: { rowIds: string[]; expanded?: boolean } }) => {
      const { rowIds, expanded } = payload;
      if (expanded) {
        state.expandedRows = uniq([...state.expandedRows, ...rowIds]);
      } else {
        state.expandedRows = state.expandedRows.filter((id) => !rowIds.includes(id));
      }
    },

    collapseAllRows: (state: ActivityState) => {
      state.expandedRows = [];
    },

    changeSelectedActivityIds: (state: ActivityState, { payload }: { payload: string[] }) => {
      state.selectedIds = payload;
    },

    changeActivityGroupBy: (state: ActivityState, { payload }: { payload: TActivityGroupBy }) => {
      state.groupBy = payload;
    },

    setSelectedActivityId: (state: ActivityState, { payload }: { payload?: string }) => {
      state.selectedActivityId = payload;
    },

    triggerToFetchActivities: (state: ActivityState) => {
      state.trigger += 1;
    },

    resetOverviewTrigger: (state: ActivityState) => {
      state.overview.triggers.ids = [];
    },

    addIdToSeriesChangedByCurrentUserSinceLastRefresh: (
      state: ActivityState,
      { payload }: { payload: string | string[] },
    ) => {
      state.seriesChangedByCurrentUserSinceLastRefresh = [
        ...state.seriesChangedByCurrentUserSinceLastRefresh,
        ...(Array.isArray(payload) ? payload : [payload]),
      ];
    },
  },
});

export const {
  fetchActivitiesRequest,
  fetchActivitiesFailure,
  fetchActivitiesSuccess,
  fetchActivitiesChildrenRequest,
  fetchActivitiesChildrenFailure,
  fetchActivitiesChildrenSuccess,

  fetchActivitiesIntoTempState,
  removeRefreshedActivitiesOfCurrentPage,
  setCurrentPageContainsDataToBeRefreshed,

  fetchTracksInSeriesSuccess,
  fetchActivitiesInTrackSuccess,
  refetchActivitySeriesByIdSuccess,
  refetchActivityByIdSuccess,
  finishLoadingActivities,

  publishActivitiesRequest,
  publishActivitiesFailure,
  publishActivitiesSuccess,

  deleteActivitiesRequest,
  deleteActivitiesFailure,
  deleteActivitiesSuccess,

  changeTablePagination,
  changeSelectedActivityIds,
  triggerToFetchActivities,
  initializeActivitiesFilter,
  changeFilterValue,
  changeFilterPeriodValue,
  setFinalFilters,
  changeExpandedRows,
  collapseAllRows,
  changeActivityGroupBy,
  setSelectedActivityId,
  resetOverviewTrigger,
  addIdToSeriesChangedByCurrentUserSinceLastRefresh,
} = allocationSlice.actions;

export type TActivityGroupBy = EActivityGroupings.FLAT | EActivityGroupings.ACTIVITY_SERIES;
export type TExportActivityOptions = {
  showDmStatusFilter?: boolean;
  examFlowV3?: boolean;
};

/* Tiny wrapper around fetchActivities for places where we cannot access current state of groupBy */
export const fetchActivitiesWithCurrentStateForGroupBy =
  (options: TExportActivityOptions) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    const state = getState();
    fetchActivities(state.activity.groupBy, options)(dispatch, getState);
  };

/* fetch activities and activity series for table
 * Idea: simplify table data, there is only basic data ( id, children, ...othersRelatedData ) for table dataSources.
 * All activity details can be read from overview.rowData. And that overview data can be used for drawers as well
 * If there is anything change, only need to update overiew.rowData[theID]
 * Important: Make sure overview.rowData always be up-to-date
 *
 * backgroundLoadingOfCurrentPage: If true, we do not show the spinner and
 * we do not put the result into the visible activities state but into another object in the state
 * from where we load it if the user clicks on "Update"
 *
 * */
export const fetchActivities =
  (
    groupBy: TActivityGroupBy,
    options: TExportActivityOptions,
    backgroundLoadingOfCurrentPage?: boolean,
    addedActivityIdsToPassThrough?: string[],
    triggerSocketEvent: boolean = true,
  ) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      // In exam flow version 3, wait until filters are initialized
      if (options.examFlowV3 && !storeState.activity.filterInitialized) return;
      const organizationId = storeState.auth?.user?.organizationId;
      const { expandedRows } = storeState.activity;
      if (!organizationId) return;

      if (!backgroundLoadingOfCurrentPage) {
        dispatch(fetchActivitiesRequest());
      }

      const filter = { ...(storeState.activity?.filter ?? {}) };

      if (!options.showDmStatusFilter) {
        delete filter.status;
      }

      const groupedCategories: Record<string, string[]> = {};
      Object.keys(filter).forEach((filterKey) => {
        const { category, field } = activityService.keyToObject(filterKey);
        if (category === 'category') {
          groupedCategories[field] = [...(groupedCategories[field] || []), filterKey];
          // delete filter[filterKey];
        }
      });

      const allCategoryKeys = Object.keys(groupedCategories);
      for (const filterKey of allCategoryKeys) {
        const values = groupedCategories[filterKey].map((fieldKey) => {
          const { key } = activityService.keyToObject(fieldKey);
          return {
            fieldId: TEObjectsService.fields[key]?.id,
            values: castArray(filter[fieldKey] as string[]).map((item) => {
              if (typeof item === 'boolean') return item ? '1' : '0';
              return item.toString();
            }),
          };
        });
        // eslint-disable-next-line no-await-in-loop
        const results = await TEObjectsService.searchObjectsByExactFields(filterKey, values);
        const allExtIdsInResults = results.map(({ extId }) => extId) as string[];

        const objectKeyInFilter = activityService.objectToKey({ category: 'objects', key: filterKey });

        // If the object is not selected in main filters
        if (!filter[objectKeyInFilter]?.length) {
          filter[objectKeyInFilter] = allExtIdsInResults;
        } else {
          filter[objectKeyInFilter] = (
            (filter[activityService.objectToKey({ category: 'objects', key: filterKey })] as string[]) || []
          ).filter((extId) => allExtIdsInResults.includes(extId));
        }

        if (!filter[objectKeyInFilter]?.length) {
          filter[objectKeyInFilter] = ['EMPTY_OBJECT']; // Keep this one to make sure it's always being inside api body
        }
      }

      const ssp = {
        groupBy: groupBy || 'ACTIVITY_SERIES',
        matchType: 'ALL',
        page: storeState.activity?.pagination[storeState.activity.groupBy].page,
        limit: storeState.activity?.pagination[storeState.activity.groupBy].perPage,
        filters: activityService.convertToFetchActivitiesFilters({
          ...filter,
          date: storeState.activity.filterPeriod,
        }),
        countOn: ['date'],
      };

      if (!options.examFlowV3) {
        ssp.filters = pick(ssp.filters, ['status']);
      }
      if (!options.showDmStatusFilter) {
        ssp.filters = omit(ssp.filters, ['status']);
      }

      dispatch(setFinalFilters(ssp.filters));

      console.log('Activities filter: ', ssp.filters);
      const response = await activityService.getActivities(organizationId, ssp);

      const allObjectsAndFields = activityService.getAllObjectsAndFieldsInActivityValues(
        response.results.flatMap((item: TActivityResultsInResponse) => {
          if ((item as TActivityResultsInResponseGroupByActivitySeries).allValues) {
            return (item as TActivityResultsInResponseGroupByActivitySeries).allValues.map(
              (sub) => sub.activityValue as TActivityValue,
            );
          }
          return (item as TActivityResultsInResponseGroupByFlat).values;
        }),
      );
      await Promise.all([
        TEObjectsService.getObjectTypes(uniq(allObjectsAndFields.objectTypes)),
        TEObjectsService.getObjects(uniq(allObjectsAndFields.objects)),
        TEObjectsService.getFields(uniq(allObjectsAndFields.fields)),
      ]);
      if (backgroundLoadingOfCurrentPage) {
        dispatch(fetchActivitiesIntoTempState({ ...response, addedActivityIds: addedActivityIdsToPassThrough }));
      } else {
        dispatch(
          fetchActivitiesSuccess({
            ...response,
          }),
        );
        if (triggerSocketEvent) {
          document.dispatchEvent(new CustomEvent(ActivityEvents.ACTIVITIES_UPDATED_VIA_SOCKET));
        }

        if (expandedRows.length) {
          await Promise.all(
            expandedRows
              .filter((row) => {
                const [seriesId, trackId] = row.split(trackDivider);
                return !trackId;
              })
              .map(async (rowId) => {
                const [seriesId, trackId] = rowId.split(trackDivider);
                const response = await activityService.getTracksInSeries(organizationId, seriesId);
                dispatch(fetchTracksInSeriesSuccess(response));
                return response;
              }),
          );
          const expandedRowsWithTrack = expandedRows
            .filter((row) => {
              const [seriesId, trackId] = row.split(trackDivider);
              return !!trackId;
            })
            .map((row) => {
              const [seriesId, trackId] = row.split(trackDivider);
              return { seriesId, trackId: parseInt(trackId, 10) };
            });
          const allSeries = uniq(expandedRowsWithTrack.map(({ seriesId }) => seriesId));
          const allTracks = uniq(expandedRowsWithTrack.map(({ trackId }) => trackId));
          const ssp = {
            groupBy: 'FLAT',
            filters: {
              activitySeriesId: {
                values: allSeries,
              },
              track: {
                values: allTracks,
              },
            },
          };
          const response = await activityService.getActivities(organizationId, ssp);
          dispatch(
            fetchActivitiesInTrackSuccess({
              activities: response.results,
            }),
          );
        }
      }
    } catch (e) {
      dispatch(fetchActivitiesFailure());
    }
  };

export const fetchActivitiesChildren =
  (activitySeriesId: string) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesId) return;
      const ssp = {
        groupBy: 'FLAT',
        filters: {
          ...(storeState.activity.finalFilterValues || {}),
          activitySeriesId: { values: [activitySeriesId] },
        },
      };
      dispatch(fetchActivitiesChildrenRequest());
      await activityService.getActivities(organizationId, ssp);
    } catch (e) {
      dispatch(fetchActivitiesChildrenFailure());
      console.error(e);
    }
  };

export const fetchTracksInSeries =
  (seriesIds: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return null;
      const responses = await Promise.all(
        seriesIds.map(async (seriesId) => {
          const response = await activityService.getTracksInSeries(organizationId, seriesId);
          dispatch(fetchTracksInSeriesSuccess(response));
          return response;
        }),
      );
      return responses;
    } catch (e) {
      dispatch(fetchActivitiesFailure());
      return e;
    }
  };

export const fetchActivitiesInTrack =
  (seriesAndTracks: { seriesId: string; trackId: number }[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return null;

      const allSeries = uniq(seriesAndTracks.map(({ seriesId }) => seriesId));
      const allTracks = uniq(seriesAndTracks.map(({ trackId }) => trackId));
      const ssp = {
        groupBy: 'FLAT',
        filters: {
          activitySeriesId: {
            values: allSeries,
          },
          track: {
            values: allTracks,
          },
        },
      };
      const response = await activityService.getActivities(organizationId, ssp);
      dispatch(
        fetchActivitiesInTrackSuccess({
          activities: response.results,
        }),
      );
      return response;
    } catch (e) {
      dispatch(fetchActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export const refetchActivitySeriesById =
  (activitySeriesIds: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds?.length) return;
      const ssp = {
        groupBy: 'ACTIVITY_SERIES',
        filters: {
          ...(storeState.activity.finalFilterValues || {}),
          activitySeriesId: { values: activitySeriesIds },
        },
      };
      dispatch(fetchActivitiesRequest());
      const seriesResponse = await activityService.getActivities(organizationId, ssp);
      const fetchedSeries = seriesResponse.results[0];
      dispatch(refetchActivitySeriesByIdSuccess(seriesResponse));
      if (!storeState.activity.expandedRows.includes(seriesResponse.results[0]?.activitySeriesId)) return;

      await Promise.all(
        activitySeriesIds.map(async (activitySeriesId: string) => {
          const tracksInSeries = new Array(fetchedSeries.numberOfTracks)
            .fill(null)
            .map((_item, itemIndex) => {
              return 1 + itemIndex;
            })
            .filter((trackId) =>
              storeState.activity.expandedRows.includes(activityService.convertToTrackId(activitySeriesId, trackId)),
            );
          const [tracksResponse, otherActivitiesInTrackResponse] = await Promise.all(
            compact([
              activityService.getTracksInSeries(organizationId!, activitySeriesId),
              tracksInSeries.length
                ? activityService.getActivities(organizationId, {
                    ...ssp,
                    groupBy: 'FLAT',
                    filters: {
                      ...ssp.filters,
                      track: {
                        values: tracksInSeries,
                      },
                    },
                  })
                : null,
            ]),
          );
          if (tracksResponse) {
            dispatch(fetchTracksInSeriesSuccess(tracksResponse));
          }
          if (otherActivitiesInTrackResponse) {
            dispatch(fetchActivitiesInTrackSuccess({ activities: otherActivitiesInTrackResponse.results }));
          }
          return null;
        }),
      );
    } catch (error) {
      fetchActivitiesFailure();
    }
  };

export const refetchActivityById =
  (activityIds: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return;
      dispatch(fetchActivitiesRequest());
      const ssp = {
        groupBy: 'FLAT',
        filters: { id: { values: activityIds } },
      };
      const response = await activityService.getActivities(organizationId, ssp);
      // const updatedActivity = await activityService.getActivityById(organizationId, activityId);
      dispatch(refetchActivityByIdSuccess(response));
    } catch {
      dispatch(fetchActivitiesFailure());
    }
  };

export const publishActivities =
  (activitySeriesIds?: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds) return null;
      dispatch(publishActivitiesRequest());
      const response = await activityService.publishActivities(
        organizationId,
        activitySeriesIds,
        storeState.activity.groupBy,
      );
      dispatch(publishActivitiesSuccess({ response, activitySeriesIds }));
      return response;
    } catch (e) {
      dispatch(publishActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export const deleteActivities =
  (activitySeriesIds?: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds) return null;
      dispatch(deleteActivitiesRequest());
      const response = await activityService.deleteActivities(
        organizationId,
        activitySeriesIds,
        storeState.activity.groupBy,
      );
      dispatch(deleteActivitiesSuccess({ response, activitySeriesIds }));
      return response;
    } catch (e) {
      dispatch(deleteActivitiesFailure());
      console.error(e);
      return e;
    }
  };

/* Begin of Socket handling */
/* ************************ */

/*
Ton of code for actually not that much purpose.

Events come in
* as ACTIVITY_UPDATE with actingUserId and activityIds
or
* as ACTIVITY_SERIES_UPDATE withOUT actingUserId and activitySeriesIds.

The code below serves these purposes:
* merge events together
* deduplicate events
* determine what to do: Refresh automatically (if done by current user) or show "Changes have been made..." box (if done by another user)

For that purose we use rxjs. It waits for a certain amount of time, merges all together into one array -
and then we use our own logic for merging events together and find out if at least one event was done by someone else.

When an event reaches socketEventDeduplicationAndHandling we push it to rxjsSourceForSocketEventDeduplication.
We pass through socketCallbacksAndFunctions and socketUseCaseAndIdsString. Those should not be merged but kept for later usage.

In rxjsSourceForSocketEventDeduplication -> mergeMap -> map we do the merge logic.

The logic of what to do (showing box or not etc) happens in rxjsSourceForSocketEventDeduplication's "subscriber".

socketEventHandlerAll handles only the socketUseCase and idsInDrawer. Splitting them so that we can be sure that all cases are handled.

socketEventHandlerSingle does the logic of refetching and displaying the "Updates have been made" box

*/

const SOCKET_DEDUPLICATION_WAIT_TIME = 1000;
const USE_CASE_IDS_SEPARATOR = '___';

// Types and type helper functions
type TSocketCallbacksAndFunctions = {
  /* These are values and functions of which we assume that they never change across websocket events. If they do, we have a problem :( */
  dispatch: Dispatch;
  getState: () => { activity: ActivityState; auth: IAuthState };
  exportActivityOptions: TExportActivityOptions;
  callbackIfActionTriggeredByCurrentUser: Function;
};

type TActiviySeriesChangedMerged = {
  activityData: TActivityUpdatePayload['applicationData'];
  seriesData: TActivitySeriesUpdatePayload['applicationData'];
  atLeastOneDoneByAnotherUser: boolean;
  socketUseCaseAndIdsString: string[];
  socketCallbacksAndFunctions: TSocketCallbacksAndFunctions;
};

type TRxjsSourceForSocketEventDeduplicationValue = {
  socketPayload: TSocketEventAndPayload['payload'];
  socketUseCaseAndIdsString: string;
  socketCallbacksAndFunctions: TSocketCallbacksAndFunctions;
};

const isActivityUpdatePayload = (inp: unknown): inp is TActivityUpdatePayload => {
  if (
    !inp ||
    typeof inp !== 'object' ||
    !('applicationData' in inp) ||
    typeof inp.applicationData !== 'object' ||
    !inp.applicationData
  ) {
    throw new Error(`isActivityUpdatePayload: Unexpected input: ${inp} || ${JSON.stringify(inp)}`);
  }

  if (
    ('addedActivityIds' in inp.applicationData && Array.isArray(inp.applicationData.addedActivityIds)) ||
    ('editedActivityIds' in inp.applicationData && Array.isArray(inp.applicationData.editedActivityIds)) ||
    ('deletedActivityIds' in inp.applicationData && Array.isArray(inp.applicationData.deletedActivityIds))
  ) {
    return true;
  }

  return false;
};

const isActivitySeriesUpdatePayload = (inp: unknown): inp is TActivitySeriesUpdatePayload => {
  if (
    !inp ||
    typeof inp !== 'object' ||
    !('applicationData' in inp) ||
    typeof inp.applicationData !== 'object' ||
    !inp.applicationData
  ) {
    throw new Error(`isActivitySeriesUpdatePayload: Unexpected input: ${inp}`);
  }
  if (
    ('editedActivitySeriesIds' in inp.applicationData && Array.isArray(inp.applicationData.editedActivitySeriesIds)) ||
    ('addedActivitySeriesIds' in inp.applicationData && Array.isArray(inp.applicationData.addedActivitySeriesIds)) ||
    ('deletedActivitySeriesIds' in inp.applicationData && Array.isArray(inp.applicationData.deletedActivitySeriesIds))
  ) {
    return true;
  }

  return false;
};
// End of: Types and type helper functions

const socketEventHandlerSingle = (
  values: Omit<TActiviySeriesChangedMerged, 'socketUseCaseAndIdsString'>,
  currentSocketUseCase: ESocketUseCase,
  allSocketUseCases: ESocketUseCase[],
  idsInDrawer: {
    activitySeriesId?: string | undefined;
    activityId?: string | undefined;
    trackdId?: string | undefined;
  },
) => {
  const { activityData, seriesData, atLeastOneDoneByAnotherUser, socketCallbacksAndFunctions } = values;
  const { dispatch, getState, exportActivityOptions, callbackIfActionTriggeredByCurrentUser } =
    socketCallbacksAndFunctions;

  const storeState = getState();

  const state = storeState.activity;

  if (state.currentPageContainsDataToBeRefreshed[currentSocketUseCase] && atLeastOneDoneByAnotherUser) {
    // "Refresh box" already visible and not trigged by current user => nothing to do. If current user triggered the complete action, we want to trigger the refresh nevertheless.
    return;
  }

  const accInfo = { ...activityData, ...seriesData };

  const dispatchRefreshOrCallCallback = (newValue: TSocketPageChangedState) => {
    if (atLeastOneDoneByAnotherUser) {
      dispatch(setCurrentPageContainsDataToBeRefreshed({ socketUseCase: currentSocketUseCase, newValue }));
    } else if (allSocketUseCases.length === 1 || currentSocketUseCase === ESocketUseCase.overviewTable) {
      /* The if statement represents a special case:
      socketEventHandlerSingle is ought to take care of one case of socketUseCase (hence the name).
      However, in order not to trigger too many refreshes, the function needs to "know" what the other values in the same "go" are doing.

      What we are stating here is:
      Only trigger a refresh if currentValue the only value in the current go
      (= allSocketUseCases.length === 1 => in practice: only the overview table is there and not the drawer)
      or: In case there are multiple values, only do stuff if I'm the overview table. */
      callbackIfActionTriggeredByCurrentUser();
    }
  };

  if (currentSocketUseCase === ESocketUseCase.overviewTable) {
    if (
      accInfo.editedActivitySeriesIds?.length &&
      state.activities.some(
        (item) => 'activitySeriesId' in item && accInfo.editedActivitySeriesIds?.includes(item.activitySeriesId),
      )
    ) {
      dispatchRefreshOrCallCallback('updated');
      return;
    }

    if (
      accInfo.deletedActivitySeriesIds?.length &&
      state.activities.some(
        (item) => 'activitySeriesId' in item && accInfo.deletedActivitySeriesIds?.includes(item.activitySeriesId),
      )
    ) {
      dispatchRefreshOrCallCallback('updated');
      return;
    }

    const currentActivityIds = state.activities
      .map((item) => {
        if (state.groupBy === EActivityGroupings.ACTIVITY_SERIES && 'activityIds' in item) {
          return item.activityIds;
        }
        if (state.groupBy === EActivityGroupings.FLAT && '_id' in item) {
          return item._id;
        }
        return [];
      })
      .flat();

    if (
      accInfo.deletedActivityIds?.length &&
      accInfo.deletedActivityIds.some((id: string) => currentActivityIds.includes(id))
    ) {
      dispatchRefreshOrCallCallback('updated');
      return;
    }

    if (
      accInfo.editedActivityIds?.length &&
      accInfo.editedActivityIds.some((id: string) => currentActivityIds.includes(id))
    ) {
      dispatchRefreshOrCallCallback('updated');
      return;
    }

    if (accInfo.addedActivityIds?.length) {
      if (atLeastOneDoneByAnotherUser) {
        fetchActivities(state.groupBy, exportActivityOptions, true, accInfo.addedActivityIds)(dispatch, getState);
      } else {
        dispatch(removeRefreshedActivitiesOfCurrentPage);
        fetchActivities(state.groupBy, exportActivityOptions, false)(dispatch, getState);
      }
    }
  } else if (currentSocketUseCase === ESocketUseCase.singleActivityDrawer) {
    if (
      accInfo.editedActivityIds?.length &&
      idsInDrawer.activityId &&
      accInfo.editedActivityIds?.includes(idsInDrawer.activityId)
    ) {
      dispatchRefreshOrCallCallback('updated');
    } else if (
      accInfo.deletedActivityIds?.length &&
      idsInDrawer.activityId &&
      accInfo.deletedActivityIds?.includes(idsInDrawer.activityId)
    ) {
      dispatchRefreshOrCallCallback('drawerItemDeleted');
    } else if (
      accInfo.deletedActivitySeriesIds?.length &&
      idsInDrawer.activitySeriesId &&
      accInfo.deletedActivitySeriesIds?.includes(idsInDrawer.activitySeriesId)
    ) {
      dispatchRefreshOrCallCallback('drawerItemDeleted');
    } else if (
      accInfo.editedActivitySeriesIds?.length &&
      idsInDrawer.activitySeriesId &&
      accInfo.editedActivitySeriesIds?.includes(idsInDrawer.activitySeriesId)
    ) {
      dispatchRefreshOrCallCallback('updated');
    }
  } else if (
    currentSocketUseCase === ESocketUseCase.activitySeriesDrawer ||
    currentSocketUseCase === ESocketUseCase.activityTrackDrawer
  ) {
    if (
      /* Right now, we have no way of knowing if the current track id  has changed. So, we do not use trackId at all for activityTrackDrawer.
       Maybe in the future we return the track id edited in the socket.  */
      accInfo.editedActivitySeriesIds?.length &&
      idsInDrawer.activitySeriesId &&
      accInfo.editedActivitySeriesIds?.includes(idsInDrawer.activitySeriesId)
    ) {
      dispatchRefreshOrCallCallback('updated');
    } else if (
      accInfo.deletedActivitySeriesIds?.length &&
      idsInDrawer.activitySeriesId &&
      accInfo.deletedActivitySeriesIds?.includes(idsInDrawer.activitySeriesId)
    ) {
      dispatchRefreshOrCallCallback('drawerItemDeleted');
    }
  }
};

const socketEventHandlerAll = (values: TActiviySeriesChangedMerged) => {
  const allSocketUseCases = values.socketUseCaseAndIdsString.map((item) => item.split(USE_CASE_IDS_SEPARATOR)[0]);
  for (const socketUseCaseAndIdsString of values.socketUseCaseAndIdsString) {
    const [socketUseCase, idsInDrawerString] = socketUseCaseAndIdsString.split(USE_CASE_IDS_SEPARATOR);
    socketEventHandlerSingle(
      values,
      socketUseCase as ESocketUseCase,
      allSocketUseCases as ESocketUseCase[], // See comment at definition of dispatchRefreshOrCallCallback for explanation
      JSON.parse(idsInDrawerString),
    );
  }
};

const rxjsSourceForSocketEventDeduplication = new Subject<TRxjsSourceForSocketEventDeduplicationValue>();

rxjsSourceForSocketEventDeduplication
  .pipe(
    groupBy(
      (val) => true, // Group all together, groupBy is just for the timer
      { duration: () => timer(SOCKET_DEDUPLICATION_WAIT_TIME) },
    ),
    mergeMap((group$) =>
      group$.pipe(
        toArray(), // Makes whole thing wait till time is up
        map((socketEventValues) => {
          const output: TActiviySeriesChangedMerged = {
            activityData: {
              addedActivityIds: [],
              editedActivityIds: [],
              deletedActivityIds: [],
            },
            seriesData: {
              addedActivitySeriesIds: [],
              editedActivitySeriesIds: [],
              deletedActivitySeriesIds: [],
            },
            atLeastOneDoneByAnotherUser: false,
            socketUseCaseAndIdsString: [],
            socketCallbacksAndFunctions: socketEventValues[0].socketCallbacksAndFunctions, // See above, we assume that this is the same for all
          };

          const state = socketEventValues[0].socketCallbacksAndFunctions.getState();
          const currentUserId = state.auth.user?.id;
          const { seriesChangedByCurrentUserSinceLastRefresh, activities, groupBy } = state.activity;

          const activitiesChangedByCurrentUser: string[] = [];

          for (const socketEventValueWhole of socketEventValues) {
            const socketPayload = socketEventValueWhole?.socketPayload ?? {};
            if (isActivityUpdatePayload(socketPayload)) {
              // Merge and deduplicate values
              output.activityData.addedActivityIds = [
                ...new Set([
                  ...(output.activityData.addedActivityIds ?? []),
                  ...(socketPayload.applicationData.addedActivityIds ?? []),
                ]),
              ];
              output.activityData.editedActivityIds = [
                ...new Set([
                  ...(output.activityData.editedActivityIds ?? []),
                  ...(socketPayload.applicationData.editedActivityIds ?? []),
                ]),
              ];
              output.activityData.deletedActivityIds = [
                ...new Set([
                  ...(output.activityData.deletedActivityIds ?? []),
                  ...(socketPayload.applicationData.deletedActivityIds ?? []),
                ]),
              ];

              if (!socketPayload.metadata.actingUserId && !currentUserId) {
                /* This case should not happen. actingUserId can be nullish,
                but currentUserId can only be nullish in case of a code problem or an infrastructure problem
                or not being logged in -> all that should not happen.
                So, if it happens, we default to the more safe option: show the box */
                output.atLeastOneDoneByAnotherUser = true;
              }

              if (socketPayload.metadata.actingUserId !== currentUserId) {
                output.atLeastOneDoneByAnotherUser = true;
              } else {
                activitiesChangedByCurrentUser.push(
                  ...(output.activityData.addedActivityIds ?? []),
                  ...(output.activityData.editedActivityIds ?? []),
                  ...(output.activityData.deletedActivityIds ?? []),
                );
              }
            } else if (isActivitySeriesUpdatePayload(socketPayload)) {
              // Merge and deduplicate values
              output.seriesData.addedActivitySeriesIds = [
                ...new Set([
                  ...(output.seriesData.addedActivitySeriesIds ?? []),
                  ...(socketPayload.applicationData.addedActivitySeriesIds ?? []),
                ]),
              ];
              output.seriesData.editedActivitySeriesIds = [
                ...new Set([
                  ...(output.seriesData.editedActivitySeriesIds ?? []),
                  ...(socketPayload.applicationData.editedActivitySeriesIds ?? []),
                ]),
              ];
              output.seriesData.deletedActivitySeriesIds = [
                ...new Set([
                  ...(output.seriesData.deletedActivitySeriesIds ?? []),
                  ...(socketPayload.applicationData.deletedActivitySeriesIds ?? []),
                ]),
              ];
            }

            if (!output.socketUseCaseAndIdsString.includes(socketEventValueWhole.socketUseCaseAndIdsString)) {
              output.socketUseCaseAndIdsString.push(socketEventValueWhole.socketUseCaseAndIdsString);
            }
          }

          /*
          At this point we know which activities the current user changed.
          And we got some changed activity series ids.

          Now, we need to remove those activity series ids which belong to the activities which the current user changed.
          */

          const allSeriesIds = [
            ...new Set([
              ...(output.seriesData.addedActivitySeriesIds ?? []),
              ...(output.seriesData.editedActivitySeriesIds ?? []),
              ...(output.seriesData.deletedActivitySeriesIds ?? []),
            ]),
          ];
          const idsOfActivitiesChangedByCurrentUserDeduplicated = [...new Set(activitiesChangedByCurrentUser)];

          let allSeriesIdsWithoutSeriesChangedByCurrentUser: string[] = [];

          if (groupBy === EActivityGroupings.FLAT) {
            const activitiesDoneByCurrentUser = activities.filter((activity) =>
              '_id' in activity && typeof activity._id === 'string'
                ? idsOfActivitiesChangedByCurrentUserDeduplicated.includes(activity._id)
                : false,
            );
            allSeriesIdsWithoutSeriesChangedByCurrentUser = allSeriesIds.filter(
              (seriesId) =>
                !activitiesDoneByCurrentUser.some(
                  (activity) =>
                    'activitySeriesGroup' in activity &&
                    activity.activitySeriesGroup &&
                    activity.activitySeriesGroup.activitySeriesId === seriesId,
                ),
            );
          } else if (groupBy === EActivityGroupings.ACTIVITY_SERIES) {
            const activitiesDoneByCurrentUser = activities.filter((activity) =>
              'activityIds' in activity && Array.isArray(activity.activityIds)
                ? idsOfActivitiesChangedByCurrentUserDeduplicated.some((activityId) =>
                    activity.activityIds.includes(activityId),
                  )
                : false,
            );
            allSeriesIdsWithoutSeriesChangedByCurrentUser = allSeriesIds.filter(
              (seriesId) =>
                !activitiesDoneByCurrentUser.some(
                  (inner) => 'activitySeriesId' in inner && inner.activitySeriesId === seriesId,
                ),
            );
          }

          if (
            allSeriesIdsWithoutSeriesChangedByCurrentUser.some(
              (item) => !seriesChangedByCurrentUserSinceLastRefresh.includes(item),
            )
          ) {
            output.atLeastOneDoneByAnotherUser = true;
          }

          return output;
        }),
      ),
    ),
  )
  .subscribe(socketEventHandlerAll);

export const socketEventDeduplicationAndHandling =
  (
    payload: TSocketEventAndPayload['payload'],
    exportActivityOptions: TExportActivityOptions,
    socketUseCase: ESocketUseCase,
    idsInDrawer: {
      activitySeriesId?: string | undefined;
      activityId?: string | undefined;
      trackdId?: string | undefined;
    },
    callbackIfActionTriggeredByCurrentUser: Function,
  ) =>
  (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    rxjsSourceForSocketEventDeduplication.next({
      socketPayload: payload,
      socketUseCaseAndIdsString: `${socketUseCase}${USE_CASE_IDS_SEPARATOR}${JSON.stringify(idsInDrawer)}`,
      socketCallbacksAndFunctions: {
        dispatch,
        getState,
        exportActivityOptions,
        callbackIfActionTriggeredByCurrentUser,
      },
    });
  };
/* End of socket handling */

export default allocationSlice.reducer;

// SELECTORS
export const activitiesSelector = (state: { activity: ActivityState }): TActivityResultsInResponse[] =>
  state.activity.activities;
export const activityOverviewSelector = (state: { activity: ActivityState }): ActivityState['overview'] =>
  state.activity.overview;
export const allActivitySeriesIdsSelector = (state: { activity: ActivityState }): string[] =>
  state.activity.allActivitySeriesIds;
export const selectedActivitiesSelector = (state: { activity: ActivityState }) => state.activity.selectedIds;
export const activitiesLoadingSelector = (state: { activity: ActivityState }) => state.activity.loadings.fetch.loading;
export const activitiesSendingSelector = (state: { activity: ActivityState }) => state.activity.loadings.send.loading;
export const activitiesDeletingSelector = (state: { activity: ActivityState }) =>
  state.activity.loadings.delete.loading;
export const activitiesPaginationSelector = (state: { activity: ActivityState }) =>
  state.activity.pagination[state.activity.groupBy];
export const activitiesTriggerSelector = (state: { activity: ActivityState }) => state.activity.trigger;

export const activityGroupBySelector = (state: { activity: ActivityState }) => state.activity.groupBy;

export const activitySelectedActivityIdSelector = (state: { activity: ActivityState }) =>
  state.activity.selectedActivityId;

export const activityFiltersSelector = (state: { activity: ActivityState }) => state.activity.filter;

export const activityFilterPeriodSelector = (state: { activity: ActivityState }) => state.activity.filterPeriod;

export const activitiesFinalFilterValuesSelector = (state: { activity: ActivityState }) =>
  state.activity.finalFilterValues;

export const activityExpandedRowsSelector = (state: { activity: ActivityState }) => state.activity.expandedRows;

export const refreshedActivitiesOfCurrentPageSelector = (state: { activity: ActivityState }) =>
  state.activity.refreshedActivitiesOfCurrentPage;

export const currentPageContainsDataToBeRefreshedSelector = (state: { activity: ActivityState }) =>
  state.activity.currentPageContainsDataToBeRefreshed;

export const reservationFieldsForTemplateSelector =
  (mode: string) =>
  (state: ApplicationState): TTemplateReservationField[] => {
    const reservationFields = state.integration.reservationFields[mode];
    if (!reservationFields) return [];
    return reservationFields?.map((field: TField) => {
      return {
        ...field,
        label: field.name,
        excludeInScheduling: false,
        valueType: 'STRING',
      };
    });
  };
