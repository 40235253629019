/* eslint-disable import/no-extraneous-dependencies */
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import castArray from 'lodash/castArray';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import XLSX from 'xlsx';
import { TWeekSelectorValue } from './components/WeekSelector/WeekSelector';
import { startAndEndToWeeks } from './components/Table/ActivitiesTable.utils';
import { orderBy } from 'lodash';
import { TActivity2Be } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import TEObjectsService from './services/TEObjects.service';
import { deepUnique } from 'utils/deepUnique';
import { getInlineString } from 'i18n/intl';

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);

export const toDurationDisplay = (minutes: number): string => {
  return moment().startOf('date').add(minutes, 'minute').format('HH:mm');
};
export const toCenterOfWeek = (week: number | string | Dayjs | Date, startOrEndWeek: 'start' | 'end' = 'start') => {
  const date = startOrEndWeek === 'start' ? dayjs(week).add(2, 'day') : dayjs(week).subtract(2, 'day');
  return dayjs((date.startOf('week').valueOf() + date.endOf('week').valueOf()) / 2);
};
export const toWeeksDisplay = (weeks: TWeekSelectorValue[]) => {
  const _startAndEndWeeks = weeks.sort().map((week: TWeekSelectorValue) => {
    if (!Array.isArray(week))
      return startAndEndToWeeks({
        startDate: dayjs(week).toJSON(),
        endDate: dayjs(week).toJSON(),
      });
    const sorted = week.sort();
    return `(${startAndEndToWeeks({
      startDate: dayjs(sorted[0]).toJSON(),
      endDate: dayjs(sorted[sorted.length - 1]).toJSON(),
    })})`;
  });
  const sorted = orderBy(_startAndEndWeeks, ['asc']);
  const chunks: (string | number)[][] = [];
  sorted.forEach((week) => {
    const currentChunk = chunks[chunks.length - 1];
    if (!currentChunk) {
      chunks.push([week]);
      return;
    }
    if (Number.isNaN(Number(week))) {
      chunks.push([week as string]);
      return;
    }
    if (Number(week) - Number(currentChunk[currentChunk.length - 1]) === 1) {
      if (currentChunk.length === 1) currentChunk.push(week);
      else currentChunk[currentChunk.length - 1] = week;
      return;
    }
    chunks.push([week]);
  });

  const startAndEndWeeks = chunks.map((item) => item.join('-')).join(', ');
  return startAndEndWeeks;
};
export const excelToJSON = (file: File): Record<string, any> => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  return new Promise((resolve) => {
    reader.onload = async (e: any) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const parsedSheets = Object.keys(wb.Sheets).reduce((results, sheetKey) => {
        return {
          ...results,
          [sheetKey]: XLSX.utils.sheet_to_json(wb.Sheets[sheetKey], {
            defval: '',
            blankrows: true,
          }),
        };
      }, {});
      resolve(parsedSheets);
    };
  });
};

export const toActivityWeekRange = (startDate: string | Date, endDate: string | Date): TWeekSelectorValue[] => {
  const diff = Math.ceil(toCenterOfWeek(endDate, 'end').diff(toCenterOfWeek(startDate), 'weeks'));
  if (!diff) return [toCenterOfWeek(dayjs(startDate)).valueOf()];
  return [
    new Array(1 + diff).fill(null).map((item, itemIdx) => {
      return toCenterOfWeek(dayjs(startDate).add(itemIdx, 'week')).valueOf();
    }),
  ];
};

export const toSpecificTimezone = (dateTime: string | Dayjs, timezone?: string) => {
  if (!timezone) return dayjs(dateTime);
  return dayjs.tz(dateTime, timezone);
};

const getUniqueMultipleValues = (values?: TActivity2Be['values'], extId?: string) => {
  const multipleValues = extId ? (values?.filter((val) => val.extId === extId) ?? []) : (values ?? []);
  return deepUnique(multipleValues.map((val) => val.value));
};

export const hasMultipleValues = (values?: TActivity2Be['values'], extId?: string): boolean => {
  return getUniqueMultipleValues(values, extId).length > 1;
};

export const getMultipleValuesDisplayValue = (values?: TActivity2Be['values'], extId?: string): string => {
  const uniqueMultipleValues = getUniqueMultipleValues(values, extId);
  const uniqueFlatMultipleValues = deepUnique(
    uniqueMultipleValues.flatMap((value) => castArray(value)).filter((val) => val !== null && !isEmpty(val)),
  );

  if (uniqueFlatMultipleValues.every((val) => typeof val === 'string')) {
    return uniqueFlatMultipleValues.map((value) => TEObjectsService.getObjectLabel(value as string)).join('; ');
  }

  return getInlineString('activities.value.number_of_different_values', uniqueMultipleValues.length);
};
