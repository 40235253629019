import { IOption, TFilterValue } from '@timeedit/ui-components';
import { TSavedFilter } from '@timeedit/ui-components/lib/src/components/Filter/Filter.type';
import dayjs, { Dayjs } from 'dayjs';

const localStoragePrefix = 'DM_';

class DMLocalStorage {
  instance: any;

  organizationId?: string;

  filters: {
    values: Record<string, TFilterValue>;
    period?: string[] | Dayjs[];
    options: Record<string, IOption[]>;
  };

  savedFilters: TSavedFilter[];

  columnsWidth: Record<string, Record<string, number>>;

  columnsVisible: Record<string, { key: string; hidden: boolean }[]>;

  constructor() {
    this.instance = {};
    this.filters = {
      values: {},
      period: [dayjs().startOf('year').toJSON(), dayjs().endOf('year').toJSON()],
      options: {},
    };
    this.savedFilters = [];
    this.columnsWidth = {};
    this.columnsVisible = {};

    const self = this;
    window.addEventListener('beforeunload', () => {
      this.set('filters', self.filters);
      this.set('columnsWidth', self.columnsWidth);
      this.set('columnsVisible', self.columnsVisible);
      this.set('savedFilters', self.savedFilters);
    });
  }

  private set<T>(key: keyof DMLocalStorage, value: T) {
    localStorage.setItem(`${localStoragePrefix}__${this.organizationId}__${key}`, JSON.stringify(value));
  }

  private get<T>(key: keyof DMLocalStorage) {
    return JSON.parse(localStorage.getItem(`${localStoragePrefix}__${this.organizationId}__${key}`) as string) as T;
  }

  init(organizationId: string) {
    this.organizationId = organizationId;
    try {
      this.filters = this.get('filters') || this.filters;
      this.columnsWidth = this.get('columnsWidth') || this.columnsWidth;
      this.columnsVisible = this.get('columnsVisible') || this.columnsVisible;
      this.savedFilters = this.get('savedFilters') || this.savedFilters;
    } catch {
      console.log('Nothing stored in localStorage, use default settings');
    }
  }

  updateFilters(filters: DMLocalStorage['filters']) {
    this.filters = filters;
  }

  updateSavedFilters(savedFilters: TSavedFilter[]) {
    this.savedFilters = savedFilters;
  }

  updateColumnsWidth(tableId: string, columnsWidth: Record<string, number>) {
    this.columnsWidth[tableId] = columnsWidth;
  }

  updateColumnsVisible(tableId: string, columnsVisible: { key: string; hidden: boolean }[]) {
    this.columnsVisible[tableId] = columnsVisible;
  }
}

export default new DMLocalStorage();
