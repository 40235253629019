import React, { useMemo } from 'react';
import { Select, Switch, Table } from 'antd';
import { TSelectedObjectValue, useCreateNewImportTemplateModal } from './CreateNewImportTemplateModal.context';
import intl from 'i18n/intl';
import { TType } from '@timeedit/registration-shared';
import TEObjectManager from 'activities/services/TEObjects.service';
import { compact, keyBy } from 'lodash';

const language = intl.messages;

type TRowData = TType & {
  alreadyUsed?: boolean;
  suffix?: string;
  key: string;
};

export default function CreateNewImportTemplateModalObjects() {
  const allFields = keyBy(TEObjectManager.fields, 'id');

  const { settings, objects, setObjects, allObjectTypes } = useCreateNewImportTemplateModal();

  const onObjectChanged = (objectType: string, dataToUpdate: Partial<TSelectedObjectValue>) => {
    const updated = {
      ...objects[objectType],
      ...dataToUpdate,
    };
    if (!updated.selected) {
      updated.fields = [];
    }
    setObjects({
      ...objects,
      [objectType]: updated,
    });
  };

  const onSelectFields = (objectType: string, fields: string[]) => {
    return setObjects({
      ...objects,
      [objectType]: {
        ...objects[objectType],
        fields,
      },
    });
  };

  const tableData = useMemo((): TRowData[] => {
    const primaryObject: TType | undefined = allObjectTypes[settings.primaryObjectType];
    const activityType: TType | undefined = allObjectTypes[settings.activityObjectType];

    const staticTeObjects = [
      {
        ...primaryObject,
        alreadyUsed: true,
        name: `${primaryObject?.name ?? settings.primaryObjectType} (${language.primary_object})`,
        key: 'primaryObject',
      },
      {
        ...activityType,
        alreadyUsed: true,
        name: `${activityType?.name ?? settings.activityObjectType}  (${language.activity_type})`,
        key: 'activityType',
      },
    ];

    if (settings.allowAdditionalObjectsOfPrimaryObjectType) {
      staticTeObjects.push({
        ...primaryObject,
        alreadyUsed: true,
        name: `${primaryObject?.name ?? settings.primaryObjectType} (${language.additional_object_of_primary_object_or_activity_type_suffix})`,
        key: primaryObject.extId,
      });
    }

    if (settings.allowAdditionalObjectsOfActivityTypeType) {
      staticTeObjects.push({
        ...activityType,
        alreadyUsed: true,
        name: `${activityType?.name ?? settings.activityObjectType} (${language.additional_object_of_primary_object_or_activity_type_suffix})`,
        key: activityType.extId,
      });
    }

    const dynamicTeObjects = Object.keys(objects).map((extId) => {
      const obj = allObjectTypes[extId];
      return {
        ...obj,
        alreadyUsed: false,
        name: obj.name,
        key: obj.extId,
      };
    });

    return [...staticTeObjects, ...dynamicTeObjects];
  }, [
    allObjectTypes,
    objects,
    settings.activityObjectType,
    settings.allowAdditionalObjectsOfPrimaryObjectType,
    settings.allowAdditionalObjectsOfActivityTypeType,
    settings.primaryObjectType,
  ]);

  const columns = [
    {
      title: <b>{language.include_in_template as string}</b>,
      key: 'name',
      sorter: (a: TRowData, b: TRowData) => a.name.localeCompare(b.name),
      width: 280,
      render: (objectType: TRowData) => {
        return (
          <div className="te-flex te-items-center">
            <Switch
              disabled={objectType.alreadyUsed}
              checked={objectType.alreadyUsed || objects[objectType.key]?.selected}
              onChange={(checked) => onObjectChanged(objectType.key, { selected: checked })}
              size="small"
              className="te-mr-2"
            />
            <span>{objectType.name}</span>
            {objectType.suffix}
          </div>
        );
      },
    },
    {
      title: <b>{language.use_object_field_instead_of_object as string}</b>,
      key: 'enabled',
      render: (objectType: TRowData) => {
        return (
          <Select
            placeholder={language.select as string}
            disabled={!objects[objectType.key]?.selected || objectType.alreadyUsed}
            allowClear
            style={{ minWidth: 250 }}
            className={'te-w-full'}
            popupMatchSelectWidth={false}
            mode="multiple"
            options={compact([...(objectType.fields ?? []), ...(objectType.parentFields ?? [])])
              ?.filter((fieldId) => allFields[fieldId])
              .map((fieldId) => ({
                value: allFields[fieldId].extId,
                label: allFields[fieldId].name,
              }))}
            value={objects[objectType.key]?.fields}
            onChange={(fields) => {
              onSelectFields(objectType.key, fields);
            }}
          />
        );
      },
    },
  ];

  return (
    <div data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_OBJECTS_SELECTION">
      <div className={'te-mb-2'}>
        <b>{language.select_object_types_and_fields as string}</b>
      </div>
      <Table columns={columns} dataSource={tableData} rowKey={'extId'} pagination={false} />
    </div>
  );
}
