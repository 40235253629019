import React, { createRef, useEffect, useState } from 'react';
import { Typography } from 'antd';
import './TruncatedText.scss';
import Tooltip from '@timeedit/ui-components/lib/src/components/Tooltip/Tooltip';
import { isEqual } from 'lodash';

type TTruncatedText = {
  text: string;
  rows?: number;
};
function TruncatedText({ text, rows = 1 }: TTruncatedText) {
  const ref = createRef<HTMLDivElement>();
  const [hasTooltip, setHasTooltip] = useState(false);

  const checkIfItHasTooltip = () => {
    const childNodes = ref?.current?.childNodes ?? [];
    if (!childNodes.length) return;
    const [truncated, fullText] = childNodes as HTMLElement[];
    setHasTooltip(truncated.offsetHeight < fullText.offsetHeight);
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      checkIfItHasTooltip();
    });
    resizeObserver.observe(ref.current as HTMLDivElement);
    return () => resizeObserver.disconnect();
  }, [ref]);

  return (
    <div className="truncated-text" ref={ref}>
      <Tooltip title={hasTooltip && text}>
        <Typography.Paragraph ellipsis={{ rows }}>{text}</Typography.Paragraph>
      </Tooltip>
      <div className="placeholder-text">{text}</div>
    </div>
  );
}

function MemorizedTruncatedText(props: TTruncatedText) {
  if (!props.text) return null;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TruncatedText {...props} />;
}

export default React.memo(MemorizedTruncatedText, (prev, next) => isEqual(prev.text, next.text));
