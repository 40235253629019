import React, { useMemo } from 'react';
import { useImportActivitiesModal } from './ImportActivitiesModal.context';
import { Alert, Spin } from 'antd';
import intl, { getInlineString } from 'i18n/intl';
import { useActivityImportResultListener } from 'activities/pages/hooks/useActivityImportResultListener';

const language = intl.messages;

export default function ImportActivitiesModalResult() {
  const { importResult, setImportResult, setImporting, importing, importAttemptId, open } = useImportActivitiesModal();

  const isImportSuccessful = importResult?.status === 'success';

  const errorCount = useMemo(() => Object.keys(importResult?.issues ?? {}).length, [importResult]);

  useActivityImportResultListener({
    callback: (data) => {
      setImportResult(data);
      setImporting(false);
    },
    importAttemptId,
    enabled: open && importing,
  });

  return importResult ? (
    <>
      <div className="import-activities-modal__grid" data-testid="IMPORT_ACTIVITIES_MODAL_RESULT">
        <span>{language.errors as string}:</span>
        <span>{errorCount}</span>
      </div>
      <div className="te-pt-2">
        {isImportSuccessful ? (
          <Alert
            showIcon
            type="success"
            message={language['activities.import.message.import_successful'] as string}
            closable
          />
        ) : (
          <div className="te-pt-2">
            {importResult.issues && <span>{language['activities.import.message.summary'] as string}:</span>}
            <div className="te-flex te-flex-col te-gap-3">
              {importResult.issues?.unknownError ? (
                <Alert showIcon type="error" message={importResult.issues?.unknownError.defaultMessage} />
              ) : (
                importResult.issues && (
                  <>
                    <Alert
                      showIcon
                      type="info"
                      message={language['activities.import.message.correct_the_errors'] as string}
                      closable
                    />
                    {Object.values(importResult.issues).map(({ defaultMessage, cell, rowNum }, index) => (
                      <Alert
                        showIcon
                        type="error"
                        // eslint-disable-next-line react/no-array-index-key -- use error index as key because it never changes.
                        key={index}
                        message={
                          cell
                            ? getInlineString(
                                'activities.import.message.issue_in_cells',
                                defaultMessage,
                                cell?.join(', '),
                              )
                            : getInlineString(
                                'activities.import.message.issue_on_rows',
                                defaultMessage,
                                rowNum?.join(', '),
                              )
                        }
                      />
                    ))}
                  </>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="import-activities-modal__grid" data-testid="IMPORT_ACTIVITIES_MODAL_RESULT">
      <span>{language.progress as string}:</span>
      <span>
        <Spin spinning /> {language['activities.import.message.import_in_progress'] as string}
      </span>
    </div>
  );
}
