export const deepUnique = <T>(arr: T[]): T[] => {
  if (!Array.isArray(arr)) {
    return [];
  }

  const seen = new Set();
  return arr.filter((item) => {
    const hash = JSON.stringify(item);
    return seen.has(hash) ? false : seen.add(hash);
  });
};
