import React from 'react';
import { Button } from '@timeedit/ui-components';
import intl from 'i18n/intl';
import { useExportReservationsModal } from './ExportReservationsModal.context';

const language = intl.messages;

export default function ExportReservationsModalFooter() {
  const { onClose, onFinish, exporting } = useExportReservationsModal();

  return (
    <div className="te-flex te-pt-2">
      <Button
        onClick={() => {
          onClose();
        }}
      >
        {language.cancel as string}
      </Button>
      <span className="te-flex-1" />
      <Button type="primary" onClick={onFinish} loading={exporting}>
        {language['activities.import.reservations.export.and.download'] as string}
      </Button>
    </div>
  );
}
