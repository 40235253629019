import { useDispatch, useSelector } from 'react-redux';
import { reviewByCourseFiltersSelector, updateReviewByCourseFilters } from '../../slices/pathway.slice';
import { Filters } from '@timeedit/ui-components';
import { EKindOfFilter, TFilterOptions } from '@timeedit/ui-components/lib/src/components/Filters/Filters.type';
import { usePathwayFilters } from './Filters/useFilters';
import intl from '../../../i18n/intl';
import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { updateCombinationFilters } from '../../slices/combinationStudents.slice';

const language = intl.messages;

function PathwayFiltersReviewingByCourse() {
  const dispatch = useDispatch();

  const {
    filterOptions: { formId, scopedObject, courseObject },
    otherFilterOptions,
  } = usePathwayFilters();

  const filtersValue = useSelector(reviewByCourseFiltersSelector);

  const onChange = (filters: Record<string, any>) => {
    updateDispatchFilters(filters);
  };

  const updateDispatchFilters = (filters: Record<string, any>) => {
    dispatch(updateReviewByCourseFilters(filters));
    dispatch(updateCombinationFilters(filters));
  };

  const filterOptions: TFilterOptions = {
    formId,
    scopedObject,
    courseObjectReviewingByCourse: courseObject,
  };
  return (
    <Filters
      filterOptions={filterOptions}
      otherFilterOptions={{
        inactiveCourse: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'boolean',
          key: 'inactiveCourse',
          label: language.inactive_course as string,
          options: [],
        },
        noOfStudyCombinations: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'number',
          key: 'noOfStudyCombinations',
          label: language.number_of_study_combinations as string,
          options: [],
        },
        expectedEnrollmentOfCourse: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'number',
          key: 'expectedEnrollmentOfCourse',
          label: language.expected_enrolment_by_course as string,
          options: [],
        },
        expectedEnrollmentOfStudyCombination: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'number',
          key: 'expectedEnrollmentOfStudyCombination',
          label: language.expected_enrolment_by_study_combination as string,
          options: [],
        },
        expectedEnrollmentDiff: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'number',
          key: 'expectedEnrollmentDiff',
          label: language.enrolment_diff as string,
          options: [],
        },
        hasCourseError: {
          kindOfFilter: EKindOfFilter.DROPDOWN,
          type: 'boolean',
          key: 'hasCourseError',
          label: language.has_course_error as string,
          options: [],
        },
        ...otherFilterOptions.course,
        ...otherFilterOptions.program,
      }}
      filtersValue={filtersValue}
      onChange={onChange}
    />
  );
}

export default PathwayFiltersReviewingByCourse;
