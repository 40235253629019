import { useEffect, useState } from 'react';
import { TType, TTEObject, TField } from '@timeedit/registration-shared';
import { TTEObject as TTEObjectInType } from '@timeedit/types/lib';
import { chunk, compact, isEmpty, keyBy, pick } from 'lodash';
import api from '../../services/api.service';
import intl from 'i18n/intl';
import { TGetObjectsOptions } from '@timeedit/ui-components/lib/src/components/ObjectFilter/ObjectFilter.type';

const language = intl.messages as Record<string, string>;
const LIMIT = 100;

type TObjectManagerField = Record<string, TField>;
type TObjectManagerObjectType = Record<string, TType>;
type TObjectManagerObject = Record<string, TTEObject>;

const doGettingItems = async (
  { extIds, options }: { extIds: string[]; options?: Record<string, any> },
  exec: ({
    extIds,
    includeReferenceFields,
  }: {
    extIds: string[];
    includeReferenceFields?: boolean;
  }) => Promise<{ results: (TField | TType | TTEObject)[] }>,
) => {
  try {
    const chunks = chunk(extIds, LIMIT);
    const objectResults = await Promise.all(
      chunks.map(async (extIds) => {
        const response = await exec({ extIds, ...(options || {}) });
        return response?.results || [];
      }),
    );
    return objectResults;
  } catch {
    return [];
  }
};

const doGettingItemsByIds = async (
  ids: number[],
  exec: ({ ids }: { ids: number[] }) => Promise<{ results: (TField | TType)[] }>,
) => {
  const chunks = chunk(ids, LIMIT);
  const objectResults = await Promise.all(
    chunks.map(async (ids) => {
      const response = await exec({ ids });
      return response?.results || [];
    }),
  );
  return objectResults;
};

class TEObjectManager {
  fields: TObjectManagerField;

  fieldsById: Record<number, string>;

  objectTypes: TObjectManagerObjectType;

  objectTypesById: Record<number, TType['extId']>;

  objects: TObjectManagerObject;

  objectsByType: Record<number, string[]>;

  loadingTypes: boolean;

  loadingFields: boolean;

  loadingObjects: boolean;

  categories: Record<number, string[]>;

  constructor() {
    this.fields = {};
    this.fieldsById = {};
    this.objectTypes = {};
    this.objectTypesById = {};
    this.objects = {};
    this.categories = {};
    this.objectsByType = {};
    this.loadingTypes = false;
    this.loadingFields = false;
    this.loadingObjects = false;
  }

  async getObjects(extIds: string[]): Promise<Partial<TObjectManagerObject>> {
    if (!extIds || !extIds.length) return {};
    const missingExtIds = compact(extIds.filter((key) => !this.objects[key]));
    if (missingExtIds.length) {
      this.loadingObjects = true;
      const results = (await doGettingItems(
        { extIds: missingExtIds, options: { includeReferenceFields: true } },
        api.findObjects,
      )) as TTEObject[][];
      results.forEach((items) => {
        items.forEach((obj) => {
          this.objects[obj.extId] = obj;
        });
      });
      this.loadingObjects = false;
    }
    return pick(this.objects, extIds);
  }

  async getAllObjects(payload: any): Promise<TTEObject[]> {
    try {
      const objectsResults = [];
      // Get first 1000 objects, and totalPages
      const { results, totalPages } = await api.findObjects({
        ...payload,
        active: 'TRUE',
        includeReferenceFields: true,
      });
      objectsResults.push(results);
      if (totalPages > 1) {
        const numberOfThreads = 10;
        const threads = new Array(numberOfThreads).fill(null);
        await Promise.all(
          threads.map(async (thread, threadIndex) => {
            for (let i = threadIndex + 2; i <= totalPages; i += numberOfThreads) {
              const result = await api.findObjects({
                ...payload,
                active: 'TRUE',
                page: i,
                includeReferenceFields: true,
              });
              objectsResults[i - 1] = result.results;
            }
          }),
        );
      }
      const allObjects = objectsResults.flatMap((objs) =>
        objs.map((obj: TTEObject) => {
          this.objects[obj.extId] = obj;
          return obj;
        }),
      );
      return allObjects;
    } catch (err) {
      console.log('Error when getting objects', err);
      return [];
    }
  }

  async getObjectsByType(
    page: number,
    options: TGetObjectsOptions & {
      typeId: number;
      extIds?: string[];
    },
  ): Promise<{ totalPages: number; objects: TTEObjectInType[]; totalResults: number }> {
    const { typeId } = options;
    let totalPagesResults = 0;
    try {
      const objectsResults = [];
      // Get first 1000 objects, and totalPages
      const { results, totalPages, totalResults } = await api.findObjects(
        {
          typeId,
          page: page || 1,
          limit: 100,
          fields: options.fields,
          extIds: options.extIds,
          includeReferenceFields: true,
        },
        {
          errorMessage:
            options.fields?.exactSearchFields?.length && options.fields?.exactSearchFields?.length > 10
              ? language.error_too_many_search_fields
              : false,
        },
      );
      if (!totalPagesResults) {
        totalPagesResults = totalPages;
      }
      objectsResults.push(results);
      const allObjects: TTEObject[] = objectsResults.flatMap((objs) =>
        objs.map((obj: TTEObject) => {
          this.objects[obj.extId] = obj;
          return obj;
        }),
      );

      this.objectsByType[typeId] = allObjects.map((obj: TTEObject) => obj.extId);
      return {
        objects: allObjects as TTEObjectInType[],
        totalPages: totalPagesResults,
        totalResults,
      };
    } catch (err) {
      console.log('Error when getting objects', err);
      return {
        objects: [],
        totalPages: 1,
        totalResults: 0,
      };
    }
  }

  init({ fields, objectTypes }: { fields?: TField[]; objectTypes: TType[] }) {
    if (fields) {
      this.fields = {
        ...this.fields,
        ...keyBy(fields, 'extId'),
      };
      this.fieldsById = Object.values(this.fields).reduce((results, field) => {
        return {
          ...results,
          [field.id]: field.extId,
        };
      }, {});
    }
    if (objectTypes) {
      this.objectTypes = {
        ...this.objectTypes,
        ...keyBy(objectTypes, 'extId'),
      };
      this.objectTypesById = Object.values(this.objectTypes).reduce((results, type) => {
        return {
          ...results,
          [type.id]: type.extId,
        };
      }, {});
    }
  }

  async getObjectTypes(extIds: string[]): Promise<Partial<TObjectManagerObjectType>> {
    const missingExtIds = compact(extIds.filter((key) => !this.objectTypes[key]));
    if (missingExtIds.length) {
      this.loadingTypes = true;
      const results = (await doGettingItems({ extIds: missingExtIds }, api.findTypes)) as TType[][];
      let fieldIds: number[] = [];
      results.forEach((items) => {
        items.forEach((obj) => {
          this.objectTypes[obj.extId] = obj;
          this.objectTypesById[obj.id] = obj.extId;
          fieldIds = [...fieldIds, ...(obj.fields ?? [])];
        });
      });
      await this.getFieldsById(fieldIds);
      this.loadingTypes = false;
    }
    return pick(this.objectTypes, extIds);
  }

  async getFields(extIds: string[]): Promise<Partial<TObjectManagerField>> {
    const missingExtIds = compact(extIds.filter((key) => !this.fields[key]));
    if (missingExtIds.length) {
      this.loadingFields = true;
      const results = (await doGettingItems({ extIds: missingExtIds }, api.findFields)) as TField[][];
      results.forEach((items) => {
        items.forEach((obj) => {
          this.fields[obj.extId] = obj;
          this.fieldsById[obj.id] = obj.extId;
        });
      });
      this.loadingFields = false;
    }
    return pick(this.fields, extIds);
  }

  async getFieldsById(ids: number[]): Promise<Partial<TObjectManagerField>> {
    const missingExtIds = ids.filter((key) => !this.fieldsById[key]);
    const fieldsResult = compact(ids.map((id) => this.fieldsById[id]));
    if (missingExtIds.length) {
      this.loadingFields = true;
      const results = (await doGettingItemsByIds(missingExtIds, api.findFields)) as TField[][];
      results.forEach((items) => {
        items.forEach((obj) => {
          this.fields[obj.extId] = obj;
          this.fieldsById[obj.id] = obj.extId;
          fieldsResult.push(obj.extId);
        });
      });
      this.loadingFields = false;
    }

    return pick(this.fields, fieldsResult);
  }

  getObjectTypeLabel(extId: string, defaultLabel?: string) {
    return this.objectTypes[extId]?.name || defaultLabel || extId;
  }

  getFieldLabel(extId: string, defaultLabel?: string) {
    return this.fields[extId]?.name || defaultLabel || extId;
  }

  getObjectLabel(extId: string, teObject?: TTEObject) {
    const obj = this.objects[extId] || teObject;
    if (!obj) return extId;
    const objectType = obj.types?.[0]; // object always be belonged in 1 type
    // @ts-ignore - Ignore until registration-shared updated
    const objectPrimaryField = this.objectTypes[this.objectTypesById[objectType]]?.primaryField;
    return obj.fields.find((field) => field.fieldId === objectPrimaryField)?.values?.[0] || extId;
  }

  getObjectTypeLabelByObject(extId: string) {
    const obj = this.objects[extId];
    if (!obj) return extId;
    const objectType = obj.types?.[0];
    if (!objectType) return extId;
    return this.objectTypes[this.objectTypesById[objectType]]?.name;
  }

  // eslint-disable-next-line class-methods-use-this
  async searchObjectsByExactFields(
    typeExtId: string,
    fields: { fieldId: number; values: string[] }[],
  ): Promise<TTEObject[]> {
    return this.getAllObjects({
      typeId: this.objectTypes[typeExtId]?.id,
      fields: {
        exactSearchFields: fields,
      },
    });
  }

  getFieldExtIdByFieldId(fieldId: number): undefined | TField {
    return this.fields[this.fieldsById[fieldId]];
  }

  async getCategories() {
    const self = this;
    if (!isEmpty(self.categories)) return self.categories;
    const allFieldsResults = await Promise.all(
      ['CATEGORY', 'CHECKBOX'].map((fieldType) => api.findFields({ fieldType })),
    );
    self.categories = {};

    allFieldsResults.forEach((item: { results: TField[] }) => {
      item.results.forEach((field) => {
        field.types.forEach((type) => {
          if (!self.categories[type]) self.categories[type] = [];
          if (!self.fields[field.extId]) {
            self.fields[field.extId] = field;
          }
          self.categories[type].push(field.extId);
        });
      });
    });
    return self.categories;
  }

  get loading() {
    return this.loadingFields || this.loadingTypes;
  }
}

export const useTEObjectWithLoading = (props: {
  exec: () => Promise<any>;
  trigger?: string | string[] | number | number[];
}) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>();

  useEffect(() => {
    const doStuffs = async () => {
      setLoading(true);
      const results = await props.exec();
      setResults(results);
      setLoading(false);
    };
    doStuffs();
  }, [props.trigger]);

  return {
    loading,
    results,
  };
};

const instance = new TEObjectManager();
export default instance;
