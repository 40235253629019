import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import wsClient from './services/socket.service';
import React, { useEffect } from 'react';
import { createCustomerSignatureContextFromToken } from '@timeedit/types/lib/types/launchDarkly.type';

import { ITEToken } from '@timeedit/types/lib/types';

// ENUMS
import { EAppId } from '@timeedit/types/lib/enums';

// COMPONENTS
import { AuthWrapper, ThemeWrapper } from '@timeedit/ui-components';
import { defaultTheme } from '@timeedit/ui-components/lib/src/assets/theme/default';

// ROUTE
import { RouterWrapper } from 'routes/RouterWrapper';

// SLICES
import { onLoginSuccess, onLogout, onRetryWebsocketConnection } from './slices/auth.slice';

// STYLES
import './App.scss';

// CONFIGS
import { configService } from './services/config.service';

// UTILS
import { fetchOrganization } from './slices/organization.slice';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { trimCloudPrefixFromCustomerSignature } from './utils/auth.helper';

notification.config({
  placement: 'bottomLeft',
  duration: 3,
});

function App() {
  const ldClient = useLDClient();
  const dispatch = useDispatch();
  const authUser = useSelector((state: any) => state.auth.user);

  useEffect(() => {
    if (authUser) {
      wsClient.init(() => {
        dispatch(onRetryWebsocketConnection());
      });
      dispatch(fetchOrganization());
    }
  }, [authUser, dispatch]);

  /**
   * EVENT HANDLERS
   */
  const setLoginToRedux = (userToken: ITEToken) => {
    try {
      const ldIdentity = createCustomerSignatureContextFromToken(userToken);
      ldClient?.identify(ldIdentity);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to identify LD: ', err);
    }
    dispatch(onLoginSuccess(userToken));
  };

  const logoutFromRedux = () => {
    ldClient?.close().catch((err) =>
      // eslint-disable-next-line no-console
      console.error('Failed to close LD: ', err),
    );
    dispatch(onLogout());
  };

  const enableLog = process.env.NODE_ENV === 'development';

  return (
    <ThemeWrapper theme={defaultTheme}>
      <AuthWrapper
        appId={EAppId.TE_DATA_MANAGER}
        authUrl={configService().REACT_APP_AUTH_DOMAIN}
        renderChildrenWhileLoading={false}
        onLoginSuccess={setLoginToRedux}
        onLogout={logoutFromRedux}
        enableLogs={enableLog}
        customerSignature={trimCloudPrefixFromCustomerSignature(authUser?.customerSignature) ?? ''}
      >
        {authUser && <RouterWrapper />}
      </AuthWrapper>
    </ThemeWrapper>
  );
}

export default App;
