import React, { useMemo } from 'react';
import { Steps } from 'antd';
import { TABS } from './ExportReservationsModal.constants';
import { useExportReservationsModal } from './ExportReservationsModal.context';

export default function ExportReservationsModalNav() {
  const { tab } = useExportReservationsModal();
  const activeStep = useMemo(() => {
    return TABS.findIndex(({ key }) => key === tab);
  }, [tab]);
  return (
    <Steps
      data-testid="EXPORT_RESERVATIONS_MODAL_NAV"
      style={{ margin: '20px 0' }}
      size="small"
      current={activeStep}
      items={TABS}
    />
  );
}
