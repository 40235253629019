import React, { ReactNode, useMemo } from 'react';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { Tag } from '@timeedit/ui-components';
import intl from 'i18n/intl';
import { LockFilled } from '@ant-design/icons';
import { Flex } from 'antd';
import { defaultTheme } from '@timeedit/ui-components/lib/src/assets/theme/default';

import './ActivityStatus.scss';

const language = intl.messages as Record<string, string>;

type TActivityStatus = {
  status: EActivityStatus;
};
type TActivityStatusInDM =
  | EActivityStatus.CREATED
  | EActivityStatus.NOT_SCHEDULED
  | EActivityStatus.IN_REVIEW
  | EActivityStatus.SUBMITTED;
const statusesMap: Record<TActivityStatusInDM, { color: string; label: string; suffix?: ReactNode }> = {
  [EActivityStatus.CREATED]: {
    color: 'default',
    label: language['activity_status.created'],
  },
  [EActivityStatus.NOT_SCHEDULED]: {
    color: 'default',
    label: language['activity_status.not_scheduled'],
  },
  [EActivityStatus.IN_REVIEW]: {
    color: 'warning',
    label: language['activity_status.in_review'],
    suffix: <LockFilled style={{ color: defaultTheme.token?.colorText }} />,
  },
  [EActivityStatus.SUBMITTED]: {
    color: 'blue',
    label: language['activity_status.submited'],
  },
};
export default function ActivityStatus(props: TActivityStatus) {
  const status = useMemo(() => {
    return props.status as TActivityStatusInDM;
  }, [props.status]);
  return (
    <Tag className="activity__status" color={statusesMap[status]?.color}>
      <Flex gap="small">
        {statusesMap[status]?.label || status}
        {statusesMap[status]?.suffix}
      </Flex>
    </Tag>
  );
}
