import { createContext, useContext } from 'react';
import { TABS } from './ExportReservationsModal.constants';
import { TImportTemplate } from 'activities/pages/types/importTemplate.type';
import { TActivityImportResultData } from '@timeedit/activity-manager-shared-lib/lib/public/types/ActivityImportPubSub.type';
import { Dayjs } from 'dayjs';

export type TExportReservationsSettings = {
  name: string;
  description?: string;
  reservationMode?: { id: number; extId: string };
  startDate?: Dayjs;
  endDate?: Dayjs;
  activityCreateTemplateId?: string;
};
interface IExportReservationsModalContext {
  settings: TExportReservationsSettings;
  setSettings: (settings: TExportReservationsSettings) => void;
  importTemplates: Record<string, TImportTemplate>;
  tab: (typeof TABS)[number]['key'];
  setTab: (tab: (typeof TABS)[number]['key']) => void;
  onClose: () => void;
  onReset: () => void;
  file?: File;
  setFile: (file?: File) => void;
  exporting: boolean;
  exportErrors: string[];
  exportResult?: TActivityImportResultData;
  setExportResult: (data: TActivityImportResultData) => void;
  onFinish: () => void;
  setExporting: (importing: boolean) => void;
}

export const initialValue: IExportReservationsModalContext = {
  settings: {
    name: '',
    description: '',
    reservationMode: undefined,
  },
  setSettings: () => {},
  importTemplates: {},
  tab: TABS[0].key,
  setTab: () => {},
  onClose: () => {},
  onReset: () => {},
  file: undefined,
  setFile: () => {},
  exporting: false,
  exportErrors: [],
  exportResult: undefined,
  setExportResult: () => undefined,
  onFinish: () => undefined,
  setExporting: () => undefined,
};

const ExportReservationsModalContext = createContext<IExportReservationsModalContext>(initialValue);
export const useExportReservationsModal = () => useContext(ExportReservationsModalContext);
export default ExportReservationsModalContext;
