import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reservationFieldsSelector } from 'slices/integration.slice';
import { TSelectedObjectValue, useCreateNewImportTemplateModal } from './CreateNewImportTemplateModal.context';
import { TTemplateReservationField } from 'activities/pages/types/importTemplate.type';
import intl from 'i18n/intl';
import TEObjectManager from 'activities/services/TEObjects.service';

const language = intl.messages;

export default function CreateNewImportTemplateModalPreview() {
  const { settings, staticObjects, objects, informationFields, fields } = useCreateNewImportTemplateModal();
  const reservationFields = useSelector(reservationFieldsSelector(settings.reservationMode));

  const selectedObjects: TSelectedObjectValue[] = useMemo(() => {
    return Object.values(objects).filter((obj) => obj.selected);
  }, [objects]);

  return (
    <div>
      <div>
        <p>
          <b>
            <span>{language.columns_included_in_your_template as string}</span>:
          </b>
        </p>
        <div className="te-mt-2 te-flex" style={{ gap: 16 }} data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_PREVIEW">
          <div className="te-flex te-flex-col te-flex-1">
            <b>{language.object_types as string}</b>
            <ul data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_PREVIEW_OBJECT_TYPES">
              {staticObjects.primaryObject && (
                <li>
                  {staticObjects.primaryObject.label} ({language.primary_object as string})
                </li>
              )}
              {staticObjects.activityType && (
                <li>
                  {staticObjects.activityType.label} ({language.activity_type as string})
                </li>
              )}
              {staticObjects.additionalObjectOfPrimaryObjectType && (
                <li>{staticObjects.additionalObjectOfPrimaryObjectType.label}</li>
              )}
              {staticObjects.additionalObjectOfActivityTypeType && (
                <li>{staticObjects.additionalObjectOfActivityTypeType.label}</li>
              )}
              {selectedObjects.map((obj) => (
                <li key={obj.extId}>{obj.label}</li>
              ))}
            </ul>
          </div>
          <div className="te-flex te-flex-col te-flex-1">
            <b>{language.object_fields as string}</b>
            <ul data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_PREVIEW_OBJECT_FIELDS">
              {selectedObjects.flatMap((obj) =>
                obj.fields?.map((field: string) => (
                  <li key={`${obj.extId}_${field}`}>
                    {TEObjectManager.getFieldLabel(field)} ({obj.label})
                  </li>
                )),
              )}
            </ul>
          </div>
          <div className="te-flex te-flex-col te-flex-1">
            <b>{language.reservation_fields as string}</b>
            {!!reservationFields?.length && (
              <ul data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_PREVIEW_RESERVATION_FIELDS">
                {fields.map((field: TTemplateReservationField) => (
                  <li key={field.extId}>{field.label}</li>
                ))}
              </ul>
            )}
          </div>
          <div className="te-flex te-flex-col te-flex-1">
            {!!informationFields.length && (
              <div className="te-mb-2">
                <b>{language.information_fields as string}</b>
                <ul data-testid="CREATE_NEW_IMPORT_TEMPLATE_MODAL_PREVIEW_ADDITIONAL_FIELDS">
                  {informationFields.map((field, fieldIdx) => (
                    // eslint-disable-next-line react/no-array-index-key -- There is no id for those fields, use index as key
                    <li key={fieldIdx}>{field.label}</li>
                  ))}
                </ul>
              </div>
            )}
            <div>
              <b>{language.additional_columns as string}</b>
              <ul>
                <li>Weeks</li>
                <li>Duration</li>
                {settings.enableTracks ? <li>Tracks</li> : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
