/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import TEObjectsService from 'activities/services/TEObjects.service';
import { castArray, compact } from 'lodash';
import { Typography, Button, Spin } from 'antd';
import { TTEObject, TType } from '@timeedit/registration-shared';
import './DatasourceSelectorV3.scss';
import {
  TExactSearchField,
  TGetObjectsOptions,
} from '@timeedit/ui-components/lib/src/components/ObjectFilter/ObjectFilter.type';
import ObjectFilterModal from '@timeedit/ui-components/lib/src/components/ObjectFilter/ObjectFilterModal';
import { TField } from '@timeedit/types/lib';
import ActivityCategoriesValue from 'activities/components/Table/ActivityCategoriesValue';
import { SUBMISSION_VALUE_TYPE } from '@timeedit/activity-manager-shared-lib/lib/internal/types/schedulingEnum.type';
import intl from 'i18n/intl';
import TruncatedText from 'activities/components/BaseElements/TruncatedText/TruncatedText';

const language = intl.messages as Record<string, string>;

type TDatasourceSelectorV3BaseProps = {
  filters?: TExactSearchField[];
  value: string[];
  field?: keyof TTEObject;
  onChange: ({ value, filters }: { value: string[]; filters: TExactSearchField[] }) => void;
  allowMultiple?: boolean;
  readonly?: boolean;
  type?: SUBMISSION_VALUE_TYPE.OBJECT | SUBMISSION_VALUE_TYPE.FILTER;
  hasMultipleValues?: boolean;
  multipleValuesRenderer?: (extId: string) => React.ReactNode;
};
type TDatasourceSelectorV3 = TDatasourceSelectorV3BaseProps & {
  typeId?: number;
  typeExtId?: string;
};
type TDatasourceSelectorV3Content = TDatasourceSelectorV3BaseProps & {
  objectType: TType;
  fields: TField[];
};

function DatasourceSelectorV3Content({
  value: propsValue,
  filters: propsFilters = [],
  allowMultiple,
  onChange,
  readonly,
  objectType,
  fields,
  type = SUBMISSION_VALUE_TYPE.OBJECT,
  hasMultipleValues,
  multipleValuesRenderer,
}: TDatasourceSelectorV3Content) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string[]>(propsValue ? castArray(propsValue) : []);
  const [filters, setFilters] = useState<TExactSearchField[]>(propsFilters || []);
  const [multipleFields, setMultipleFields] = useState<number[]>([]);

  useEffect(() => {
    if (open) {
      setFilters(propsFilters);
      setValue(propsValue ? castArray(propsValue) : []);
    }
  }, [open]);

  const objectsGetter = (page: number, options: TGetObjectsOptions) => {
    return TEObjectsService.getObjectsByType(page, { ...options, typeId: objectType?.id });
  };

  const displayValue = useMemo(() => {
    if (hasMultipleValues && typeof multipleValuesRenderer === 'function') {
      return multipleValuesRenderer(objectType.extId);
    }
    if (!propsValue?.length && !filters?.length) return null;
    if (!propsValue?.length) {
      return (
        <ActivityCategoriesValue
          flatten
          key="categories"
          activityValueCategories={propsFilters.map(({ fieldId, values }) => ({ id: fieldId, values }))}
        />
      );
    }
    return compact(castArray(propsValue))
      .map((extId) => TEObjectsService.getObjectLabel(extId) || extId)
      .join(', ');
  }, [propsValue]);

  const searchableFields = useMemo(() => {
    return (
      objectType.fields?.filter(
        (fieldId) => TEObjectsService.fields[TEObjectsService.fieldsById[fieldId]]?.searchable,
      ) ?? []
    );
  }, [objectType]);

  return (
    <div
      className={`datasource-element te-flex ${type === SUBMISSION_VALUE_TYPE.FILTER && typeof displayValue !== 'string' ? 'te-flex-col' : `te-items-center ${displayValue !== null ? 'te-gap-x-2' : ''} te-flex-wrap`}`}
    >
      {displayValue && (
        <div className="datasource-element__rendered-value">
          {typeof displayValue === 'string' ? (
            <TruncatedText text={displayValue as string} rows={2} />
          ) : (
            <Typography.Text>{displayValue}</Typography.Text>
          )}
        </div>
      )}
      {!readonly && (
        <div>
          <Button size="small" onClick={() => setOpen(true)}>
            {language.edit}
          </Button>
          <ObjectFilterModal
            destroyOnClose
            title={`${objectType.name}${type === SUBMISSION_VALUE_TYPE.FILTER ? ` - ${language.edit_object_filter}` : ''}`}
            open={open}
            onSubmit={({ value, filters, multipleFields }) => {
              setValue(value || []);
              setFilters(filters);
              onChange({ value: value || [], filters });
              setOpen(false);
              setMultipleFields(multipleFields || []);
            }}
            onCancel={() => setOpen(false)}
            fields={fields}
            objectTypeId={objectType.id}
            objectsGetter={objectsGetter}
            searchableFields={searchableFields}
            allowMultiple={allowMultiple}
            value={value}
            defaultValue={value}
            filters={filters}
            defaultFilters={filters}
            labelRenderer={(obj) => {
              return TEObjectsService.getObjectLabel(obj.extId);
            }}
            wrapClassName={`object-filter-modal ${!fields?.length ? 'object-filter-modal--no-filter' : ''}`}
            zIndex={fields?.length ? 1201 : 500}
            multipleFields={multipleFields}
          />
        </div>
      )}
    </div>
  );
}

export default function DatasourceSelectorV3Wrapper(props: TDatasourceSelectorV3) {
  const { typeExtId, typeId } = props;
  const [loading, setLoading] = useState(false);
  const [objectType, setObjectType] = useState<undefined | TType>();

  useEffect(() => {
    const doGettingObject = async () => {
      setLoading(true);
      if (typeId) {
        setObjectType(TEObjectsService.objectTypes[TEObjectsService.objectTypesById[typeId]]);
      }
      if (typeExtId) {
        setObjectType((await TEObjectsService.getObjectTypes([typeExtId]))?.[typeExtId]);
      }
      setLoading(false);
    };
    if (typeId || typeExtId) {
      doGettingObject();
    }
  }, []);

  const fields = useMemo(() => {
    if (!objectType) return [];
    return TEObjectsService.categories[objectType.id]?.map((fieldExtId) => TEObjectsService.fields[fieldExtId]) ?? [];
  }, [objectType]);

  if (loading) return <Spin spinning />;

  if (!objectType) return null;

  return <DatasourceSelectorV3Content {...props} fields={fields as unknown as TField[]} objectType={objectType} />;
}
